import React, { useState } from "react";
import { HStack, Stack, Text } from "native-base";
import { Switch } from "react-native";

const VVDAccountTabItem = ({ deleteMe, idx, tabName, setDeleteMe }) => {
  let [isEnabled, setIsEnabled] = useState(false);

  return (
    <Stack
      flexDirection="row"
      p={1}
      m={1}
      key={idx}
      borderColor="black"
      borderWidth={"1"}
      width="250"
      bgColor={"gray.700"}
    >
      {isEnabled ? true : false}
      <Switch
        value={isEnabled}
        trackColor={{
          false: "#0891b2",
          true: "#880808",
        }}
        ios_backgroundColor={"#0891b2"}
        key={idx}
        onValueChange={async (enabledState) => {
          let temp = deleteMe;
          temp[idx] = enabledState;
          setDeleteMe(temp);
          setIsEnabled(enabledState);
        }}
      />
      <HStack
        flexDir="row"
        flexGrow={10}
        justifyContent="space-evenly"
        space={1}
        alignItems="center"
      >
        <Text pl="2" color={"white"} bold>{tabName !== "BLANK" ? tabName : null}</Text>
      </HStack>
    </Stack>
  );
}

export default VVDAccountTabItem;
