import * as ImageManipulator from "expo-image-manipulator";

const ImageSizer = async (image, longSide) => {
  try {
    const result = await ImageManipulator.manipulateAsync(image, [longSide], {
      compress: 0.5,
      format: ImageManipulator.SaveFormat.JPEG,
    });

    return result;
  } catch (error) {
    console.error("ImageManipulator error", error);
  }
};

export default ImageSizer;
