import React, { useState, useEffect } from "react";

import { Button, Center, Modal, SimpleGrid } from "native-base";
import { SSRProvider } from "@react-aria/ssr";
import VVDMemoServerItem from "./VVDMemoServerItem";

import apiSrvr from "../utilsSrvr";
import useAuth from "../auth/useAuth";

const VVDMemoServerList = ({
  memosObj,
  setMemosObj,
  setMemosTotal,
  setModalVisible,
}) => {
  const [serverGroups, setServerGroups] = useState([]);
  const { user, setUser } = useAuth();
  const email = user;

  const LoadServerGroups = async (gType) => {
    const temp = await apiSrvr.GetStoredTagGroups(gType, email);
    setServerGroups(temp);
  };

  const DeleteServerGroup = async (groupName, gType) => {
    await apiSrvr.DeleteServerGroup(groupName, gType, email);
  };

  useEffect(() => {
    LoadServerGroups(1);
  }, []);
  return (
    <Modal.Content {...{
      marginBottom: "auto",
      marginTop: 5,
    }}>
      <Modal.CloseButton onClose={() => setModalVisible(false)} />
      <Modal.Header>Server Memo Groups</Modal.Header>
      <Modal.Body>
        <Center>
          {serverGroups.length === 0 ? (
            <Button variant={"ghost"} isLoading isLoadingText="Loading memos.">
              No stored memos.
            </Button>
          ) : (
            <SimpleGrid columns={1} width={"100%"} alignItems="center">
              <SSRProvider>
                {serverGroups.map((item, index) => (
                  <VVDMemoServerItem
                    DeleteServerGroup={DeleteServerGroup}
                    LoadServerGroups={LoadServerGroups}
                    memoTxt={Object.values(item)[1].memoData}
                    memoName={Object.values(item)[1].memoName}
                    memosObj={memosObj}
                    setMemosObj={setMemosObj}
                    setMemosTotal={setMemosTotal}
                    key={index}
                  />
                ))}
              </SSRProvider>
            </SimpleGrid>
          )}
        </Center>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group>
          <Button onPress={() => setModalVisible(false)}>Done</Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  );
}
export default VVDMemoServerList;
