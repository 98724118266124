import React, { useState } from "react";
import {
  AlertDialog,
  Button,
  Input,
  Switch,
  Text,
  Modal,
  HStack,
} from "native-base";

import apiSrvr from "../utilsSrvr";
import useAuth from "../auth/useAuth";
import VVDAlert from "./VVDAlert";

const VVDTagGroupUpLoadDLG = ({
  allLbls,
  extraData,
  setModalVisible,
  tabGroup,
}) => {
  const [newText, setNewText] = useState(tabGroup);
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [err, setShowErr] = useState(false);
  const email = useAuth().user;
  // console.log("newText=" + newText, "tabGroup=" + tabGroup);
  const LoadServerGroupName = async () => {
    return await apiSrvr.GetStoredTagGroupNames(0, email);
  };

  return (
    <Modal.Content {...{
      marginBottom: "auto",
      marginTop: 5,
    }}>
      <AlertDialog
        size="lg"
        isOpen={alertVisible}
        onClose={() => {
          setAlertVisible(false);
        }}
      >
        {currentModal}
      </AlertDialog>

      <Modal.Header>Enter 'Tag Group' name to upload.</Modal.Header>
      <Modal.Body>
        <Input
          placeholder={tabGroup}
          size="lg"
          spellCheck={false}
          autoCapitalize={"none"}
          autoCompleteType={"off"}
          autoCorrect={false}
          onChangeText={(tempText) => {
            setNewText(tempText);
          }}
          autoFocus
        />
        {err ? (
          <Badge colorScheme="danger.600">
            <Text>Tag group name exists.</Text>
          </Badge>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <HStack alignItems="center" flex={1}>
          <Switch
            value={isEnabled}
            trackColor={{
              false: "#0891b2",
              true: "#880808",
            }}
            ios_backgroundColor={"#0891b2"}
            onChange={() => {
              setIsEnabled(!isEnabled);
            }}
          />
          <Text pl="2" italic={isEnabled} bold={isEnabled}>
            Private
          </Text>
        </HStack>
        <Button.Group>
          <Button
            isLoading={loading}
            onPress={async () => {
              setLoading(true);

              LoadServerGroupName().then((serverNameGroups) => {
                // console.log("LoadServerGroupName", LoadServerGroupName);
                const rslt = serverNameGroups.filter((wrd) => {
                  // console.log(newText + "=rslt", wrd);
                  return wrd.toLowerCase() === newText.toLowerCase();
                });
                // console.log("rslt rslt rslt", rslt.length);

                if (rslt.length !== 0) {
                  setCurrentModal(
                    <VVDAlert
                      title="CAUTION"
                      message={
                        "Tag group name '" +
                        newText +
                        "' already exists.  Tag group names must be unique."
                      }
                      setAlertVisible={setAlertVisible}
                    >
                      <Button onPress={() => setAlertVisible(false)}>OK</Button>
                    </VVDAlert>
                  );
                  setAlertVisible(true);
                  setLoading(false);
                } else {
                  const temp = JSON.stringify({
                    private: isEnabled,
                    extraData,
                    allLbls,
                  });
                  apiSrvr.uploadTagGroup(
                    newText,
                    temp,
                    0,
                    email
                  );
                
                  setLoading(false);
                  setModalVisible(false);
                }
              });
            }}
          >
            OK
          </Button>
          <Button onPress={() => setModalVisible(false)}>Cancel</Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  );
};

export default VVDTagGroupUpLoadDLG;
