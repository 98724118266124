import axios from "axios";
const funcPath = "https://us-central1-theimg-e296e.cloudfunctions.net/app/";
//const funcPath = "http://127.0.0.1:5001/theimg-e296e/us-central1/app/";

//

const onGetWord = async (theText) => {
  return await axios
    .get(funcPath + "vizzydef/?word=" + theText.toLowerCase())
    .then((onfulfilled) => {
      return onfulfilled.data;
    })
    .catch((onRejected) => {
      console.error("onGetWord Rejected", onRejected);
    });
};

const GetPublicGroup = async (gType = 0) => {
  return await axios
    .get(funcPath + "datagetpublic/?&gType=" + gType)
    .then((onfulfilled) => {
      return onfulfilled.data;
    })
    .catch((onRejected) => {
      console.error("GetPublicGroup onRejected", onRejected);
    });
};

const GetStoredTagGroupNames = async (gType = 0, email) => {
  return await axios
    .get(funcPath + "datanameget/?email=" + email + "&gType=" + gType)
    .then((onfulfilled) => {
      // console.log("GetStoredTagGroupNames onfulfilled", onfulfilled.data);
      return onfulfilled.data;
    })
    .catch((onRejected) => {
      console.error("GetStoredTagGroupNames onRejected", onRejected);
    });
};

const GetStoredTagGroups = async (gType = 0, email) => {
  let tempReturn = [];
  // console.log(funcPath + "dataget/?email=" + email);
  return await axios
    .get(funcPath + "dataget/?email=" + email + "&gType=" + gType)
    .then((onfulfilled) => {
      if (onfulfilled.data.length === 0) {
        return [];
      } else {
        onfulfilled.data.forEach((element) => {
          if (gType === 0) {
            tempReturn.push({
              groupName: Object.keys(element),
              groupData: JSON.parse(Object.values(element)),
            });
          } else {
            tempReturn.push({
              memoName: Object.keys(element),
              memoData: JSON.parse(Object.values(element)),
            });
          }
        });
        return tempReturn;
      }
    })
    .catch((onRejected) => {
      console.error("GetStoredTagGroups onRejected", onRejected);
      return [];
    });
};

const DeleteDataNode = async (gType = 0, email) => {
  // console.log(email + " = gType", gType);
  const postObj = {
    email,
  };
  return await axios
    .post(funcPath + "userdelete/", postObj)
    .then((onfulfilled) => {
      console.log("DeleteDataNode " + gType + " SUCCESS");
      return onfulfilled;
    })
    .catch((onRejected) => {
      console.error("DeleteDataNode " + gType + "onRejected", onRejected);
    });
};

const DeleteServerGroup = async (deleteTab, gType = 0, email) => {
  // console.log("gType", gType);
  const postObj = {
    email,
    tGroup: deleteTab,
    gType,
  };

  return await axios
    .post(funcPath + "datadelete/", postObj)
    .then((onfulfilled) => {
      console.log("deleteServerGroup SUCCESS");
      return onfulfilled;
    })
    .catch((onRejected) => {
      console.error("deleteServerGroup onRejected", onRejected);
    });
};

const DeleteUser = async (email) => {
  const postObj = { email };
  return await axios
    .post(funcPath + "userdelete/", postObj)
    .then((onfulfilled) => {
      console.log("DeleteUser SUCCESS");
      return onfulfilled;
    })
    .catch((onRejected) => {
      console.error("DeleteUser onRejected", onRejected);
    });
};

const LabelMe = async (imgInfo, token) => {
  let urlString = funcPath + "labeler/";
  // console.log(urlString, imgInfo);
  return await axios
    .post(urlString, imgInfo)
    .then((onfulfilled) => {
      return onfulfilled.data;
    })
    .catch((onrejected) => {
      console.error("storeImage = onrejected", onrejected);
      return;
    });
};

const uploadTagGroup = async (tabCurrent, groupData, gType = 0, email) => {
  // console.log("gType", gType);
  //const email = "eximietate.vd@gmail.com";
  const postObj = { email, groupData, tabCurrent, gType };

  return await axios
    .post(funcPath + "dataput/", postObj)
    .then(() => {
      console.log("uploadTagGroup success");
      return true;
    })
    .catch((onRejected) => {
      console.error("uploadTagGroup onRejected", onRejected);
      return false;
    });
};

const UpdatePrv = async (email, gType, group, state) => {
  // console.log("UpdatePrv", group);
  const postObj = {
    email,
    group,
    gType,
    state,
  };
  return await axios
    .post(funcPath + "prvud/", postObj)
    .then((onfulfilled) => {
      return onfulfilled.data;
    })
    .catch((onrejected) => {
      console.error("UpdatePrv = onrejected", onrejected);
      return [];
    });
};

export default {
  DeleteDataNode,
  DeleteServerGroup,
  DeleteUser,
  GetPublicGroup,
  GetStoredTagGroupNames,
  GetStoredTagGroups,
  LabelMe,
  onGetWord,
  uploadTagGroup,
  UpdatePrv,
};
