import React, { useState } from "react";
import { initializeApp } from "@firebase/app";

//import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "@firebase/auth";
import { Badge, Box, Button, Modal, Text, Spacer, VStack } from "native-base";
import * as Yup from "yup";

import {
  AppForm,
  FormError,
  FormField,
  FormSubmitButton,
} from "../parts/forms";

import firebaseConfig from "../../firebaseConfig";
import useAuth from "../auth/useAuth";
import authStorage from "../auth/storage";
import AuthFuncs from "../auth/AuthFuncs.js";
import VVDRSDLG from "../parts/VVDRSDLG";

import TOS from "../parts/forms/TOS";


const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
});

const SignIn = () => {
  const [currentModal, setCurrentModal] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [formError, setFormError] = useState("");

  const { user, setUser } = useAuth();

  const handleSubmit = async (loginInfo) => {
    setFormError(
      await AuthFuncs.SI(auth, authStorage, setUser, loginInfo)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return err;
        })
    );
  };

  try {
    initializeApp(firebaseConfig);

  } catch (err) {
    console.error("firebaseConfig ini", err);
  }

  const auth = getAuth();
  //connectAuthEmulator(auth, "http://127.0.0.1:9099");

  return (
    <Badge flex={1} justifyContent="center">
      <Modal
        size="xl"
        closeOnOverlayClick={false}
        isOpen={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
      >
        {currentModal}
      </Modal>
      {/* <Padder> */}
      <VStack>
        <AppForm
          initialValues={{ email: "", password: "" }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Box
            size="xs"
            borderWidth="1"
            alignItems="center"
            padding="2"
            bg="coolGray.300"
          >
            <FormField
              autoCapitalize="none"
              autoCompleteType="off"
              autoCorrect={false}
              inputHelp="enter an email"
              icon="email"
              label="email"
              name="email"
              multiline={false}
              type="email"
              size="md"
              width="100%"
            />
            <Spacer size="2" />
            <FormField
              autoCapitalize="none"
              autoCompleteType="off"
              autoCorrect={false}
              icon="lock"
              label="password"
              name="password"
              multiline={false}
              type="password"
              size="md"
              width="100%"
            />
            <Spacer size="6" />
            <FormSubmitButton title="Sign In" />
            <Spacer size="1" />
            <FormError error={formError} visible={formError} />
            <Spacer size="5" />
            <Button
              onPress={() => {
                setCurrentModal(
                  <VVDRSDLG
                    auth={auth}
                    AuthFuncs={AuthFuncs}
                    setModalVisible={setModalVisible}
                  />
                );
                setModalVisible(true);
              }}
            >
              Forgot Password
            </Button>
            <Text>{TOS.CPYRGT}</Text>
          </Box>
        </AppForm>
      </VStack>
      {/* </Padder> */}
    </Badge>
  );
};

export default SignIn;
