import React from "react";
import { Button } from "native-base";
import { useFormikContext } from "formik";

const FormSubmitButton = ({ title }) =>{
  const { handleSubmit } = useFormikContext();
  return <Button onPress={handleSubmit}>{title}</Button>;
}

export default FormSubmitButton;
