import React, { useState } from "react";
import { Switch } from "react-native";
import { useFormikContext } from "formik";
import { AlertDialog, Button, HStack, Text, VStack } from "native-base";
import TOS from "./TOS";

import FormError from "./FormError";
import VVDAlert from "../VVDAlert";

const FormSwitch = ({ name, ...otherProps }) => {
  const { errors, setFieldValue } = useFormikContext();
  const [isSwitched, setIsSwitched] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);

  return (
    <>
      <AlertDialog
        size="lg"
        isOpen={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
      >
        {currentModal}
      </AlertDialog>
      <VStack
        alignItems="center"
        width="100%"
        justifyContent="center"
        space="2"
      >
        <Button
          // colorScheme="info"
          onPress={() => {
            setCurrentModal(
              <VVDAlert
                title={TOS.TITLE}
                message={TOS.MSG}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
              >
                <Button onPress={() => setModalVisible(false)}>OK</Button>
              </VVDAlert>
            );
            setModalVisible(true);
          }}
        >
          Terms of Service
        </Button>
        <HStack space={2}>
          <Text>Disagree</Text>
          <Switch
            value={isSwitched}
            // trackColor={{
            //   false: "#e5e5e5",
            //   true: "#0891b2",
            // }}
            // ios_backgroundColor={"#e5e5e5"}
            onValueChange={(switchState) => {
              setFieldValue(name, switchState);
              setIsSwitched(switchState);
            }}
            {...otherProps}
          />
          <Text>Agree</Text>
        </HStack>
      </VStack>
      <FormError error={errors[name]} visible={true} />
    </>
  );
};

export default FormSwitch;
