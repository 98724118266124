import AStorage from "@react-native-async-storage/async-storage";

const gType = ["@tabdata", "@memodata"];

const GenerateKey = () => {
  return Math.floor(Math.random() * Date.now() + 1).toString();
};

const DeleteAsyncAllKeys = async () => {
  const keys = await AStorage.getAllKeys();

  keys.forEach((key) => {
    try {
      // console.log("show key", key);
      AStorage.removeItem(key);
    } catch (e) {
      console.error("DeleteAsyncAllKeys error", e);
    }
  });
};

const DeleteAsyncKey = async (group) => {
  // console.log("DeleteAsyncKey", group);
  try {
    AStorage.removeItem(group);
  } catch (e) {
    console.error("DeleteAsyncKey error", e);
  }
};

const GetAsyncAllKeys = async () => {
  try {
    return await AStorage.getAllKeys();
  } catch (e) {
    console.error("GetAsyncAllKeys", e);
  }
};

const GetAsyncItem = async (tabTitle) => {
  return await AStorage.getItem(tabTitle)
    .then((rst) => {
      // console.error("GetAsyncItem rst", rst);
      return JSON.parse(rst);
    })
    .catch((err) => console.error("GetAsyncItem", err));
};

const GetAsyncTabsData = async (gID = 0) => {
  return await AStorage.getItem(gType[gID])
    .then((rst) => JSON.parse(rst))
    .catch((err) => console.error("GetAsyncTabsData", err));
};

const SetAsyncSetItem = async (itemName, jsonValue) => {
  await AStorage.setItem(itemName, jsonValue)
    .then(() => {
      //if (itemName !== "colorMode" || itemName !== "guestMode") {
        // console.log("SetAsyncSetItem=" + itemName, "GOOD");
     // }
    })
    .catch((err) => {
      console.error("SetAsyncSetItem=" + itemName, err);
    });
};

const GetTabLabels = async (tempTabsObj) => {
  return new Promise((resolve) => {
    //  console.log("----GetTabLabels-", tempTabsObj.length);

    let allSelectedWords = "";
    let selectedWords = [];
    let allCount = 0;
    let tabs = null;
    // //get the selected tab names
    try {
      tabs = tempTabsObj
        .filter((tab) => {
          //        console.log(tab.tabName, tab);
          return tab.selected ? true : false;
          // return tab;
        })
        .map((obj) => obj);
    } catch (error) {
      console.error("GetTabLabels selected error", GetTabLabels);
    }

    if (tabs.length === 0) {
      resolve(["", 0]);
    }
    //console.log("tabs", tabs.length);

    let cnt = 0;
    try {
      tabs.map(async (tab) => {
        let wordsGroup = "";
        await GetAsyncItem(tab.tabName).then(async (onfulfilled) => {
          if (onfulfilled) {
            selectedWords = onfulfilled[1]
              .filter((obj) => obj.selected === true)
              .map((obj) => obj.word);

            let currentWord = "";

            await selectedWords.forEach((wrd) => {
              const originalWord = wrd.split(" ");
              currentWord = "";
              originalWord.forEach((wrd) => {
                currentWord +=
                  wrd.charAt(0).toUpperCase() + wrd.substr(1).toLowerCase();
              });
              wordsGroup += "#" + currentWord + " ";
            });

            allSelectedWords += wordsGroup + "\n";
            allCount += tab.tagCount;
            cnt++;
          }
        });

        if (cnt === tabs.length) {
          resolve([allSelectedWords, allCount]);
        }
      });
    } catch (error) {
      console.error("GetAsyncItem tabs.map", error);
    }
  });
};

const UpDateTotalSel = (tabsObj) => {
  let tempCount = 0;
  tabsObj.forEach((tab) => {
    tempCount += tab.tagCount;
  });
  return tempCount;
};

const TagGroupNameConflictCheck = (tabsObj, groupName) => {
  try {
    const tbsObjExist = tabsObj.filter(
      (obj) => obj.tabName.toLowerCase() === groupName.toLowerCase()
    );

    return tbsObjExist.length >= 1 ? true : false;
  } catch (error) {
    console.error("TagGroupNameConflictCheck", error);
  }
};

const MemoGroupNameConflictCheck = (memosObj, memoName) => {
  const memosObjExist = memosObj.filter((obj) => {
    return obj.memoName.toLowerCase() === memoName.toLowerCase();
  });

  return memosObjExist.length >= 1 ? true : false;
};

const TagGroupNameConflict = (tabsObj, groupName) => {
  const result = TagGroupNameConflictCheck(tabsObj, groupName);

  if (result) {
    return false;
  } else {
    return true;
  }
};

export default {
  DeleteAsyncAllKeys,
  DeleteAsyncKey,
  GenerateKey,
  GetAsyncItem,
  GetAsyncTabsData,
  GetAsyncAllKeys,
  GetTabLabels,
  MemoGroupNameConflictCheck,
  SetAsyncSetItem,
  TagGroupNameConflict,
  TagGroupNameConflictCheck,
  UpDateTotalSel,
};
