import AStorage from "@react-native-async-storage/async-storage";

const key = "authToken";

const storeToken = async (authToken) => {
  try {
    return await AStorage.setItem(key, authToken);
  } catch (error) {
    console.error("Error setting tokem:  ", error);
  }
};

const getUser = async () => {
  return await getToken();
};

const getToken = async () => {
  
  return await AStorage.getItem(key);

};

const removeToken = async () => {
  try {
    AStorage.removeItem(key);
  } catch (e) {se
    console.error("removeToken error", e);
  }
};

export default { getUser, removeToken, storeToken };
