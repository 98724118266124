import React, { useState, useEffect } from "react";

import { Button, Center, Modal, SimpleGrid, Stack } from "native-base";
// import { SSRProvider } from "@react-aria/ssr";
import VVDServerItem from "./VVDServerItem";

import apiSrvr from "../utilsSrvr";
import useAuth from "../auth/useAuth";

const VVDServerList = ({
  colorMode,
  tabsObj,
  setModalVisible,
  setTagGroup,
  storeData,
}) => {
  const [serverGroups, setServerGroups] = useState([]);
  const { user, setUser } = useAuth();
  const email = user;

  const UpdateServerGroupsPrv = async (groupName, state) => {
    await apiSrvr.UpdatePrv(email, 0, groupName, state);
  };

  const LoadServerGroups = async () => {
    const temp = await apiSrvr.GetStoredTagGroups(0, email);
    setServerGroups(temp);
  };

  const DeleteServerGroup = async (groupName) => {
    await apiSrvr.DeleteServerGroup(groupName, 0, email);
  };

  useEffect(() => {
    LoadServerGroups();
  }, []);
  return (
    <Modal.Content
      {...{
        marginBottom: "auto",
        marginTop: 5,
      }}
    >
      <Modal.CloseButton onClose={() => setModalVisible(false)} />
      <Modal.Header>Server Tag Groups.</Modal.Header>
      <Modal.Body>
        <Center>
          {serverGroups.length === "undefined" || serverGroups.length === 0 ? (
            <Button
              variant={"ghost"}
              isLoading
              isLoadingText="Loading tag groups."
            >
              No stored tag groups.
            </Button>
          ) : (
            <SimpleGrid columns={1} width={"100%"} alignItems="center">
              <Stack>
                {serverGroups.map((item, index) => (
                  <VVDServerItem
                    colorMode={colorMode}
                    DeleteServerGroup={DeleteServerGroup}
                    groupName={item.groupName[0]}
                    groupData={item.groupData.allLbls}
                    groupPrv={item.groupData.private}
                    key={index}
                    LoadServerGroups={LoadServerGroups}
                    setTagGroup={setTagGroup}
                    storeData={storeData}
                    tabsObj={tabsObj}
                    UpdateServerGroupsPrv={UpdateServerGroupsPrv}
                    //lmData={item.groupData.extraData?item.groupData.extraData.lmData:null}
                    //ftData={item.groupData.extraData?item.groupData.extraData.ftData:null}
                    extraData={
                      item.groupData.extraData ? item.groupData.extraData : null
                    }
                  />
                ))}
              </Stack>
            </SimpleGrid>
          )}
        </Center>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group>
          <Button onPress={() => setModalVisible(false)}>Done</Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  );
};
export default VVDServerList;
