import React, { useState } from "react";
import {
  AlertDialog,
  Button,
  Divider,
  HStack,
  Icon,
  Stack,
  Text,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";

import VVDAlert from "./VVDAlert";
import api from "../utils";

const VVDMemoServerItem = ({
  DeleteServerGroup,
  LoadServerGroups,
  memoTxt,
  memoName,
  memosObj,
  setMemosTotal,
  setMemosObj,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [currentAlert, setCurrentAlert] = useState(false);

  return (
    <Stack
      borderColor="black"
      borderWidth="1"
      mb="1"
      alignItems="center"
      bgColor={"gray.700"}
    >
      <AlertDialog
        size="lg"
        isOpen={alertVisible}
        onClose={() => {
          setAlertVisible(false);
        }}
      >
        {currentAlert}
      </AlertDialog>

      <HStack m={1} alignItems={"center"} flex={1} width="full">
        <Button
          variant="solid"
          leftIcon={
            isDeleting ? null : <Icon as={<Ionicons name="trash-outline" />} />
          }
          isLoading={isDeleting}
          onPress={() => {
            console.log("DELETE");
            setCurrentAlert(
              <VVDAlert
                colorScheme="warning"
                title="CAUTION"
                message={
                  "'" +
                  memoName +
                  "' will be erased from the server, permanently."
                }
                setAlertVisible={setModalVisible}
              >
                <HStack flexDirection="row" space="1">
                  <Button
                    colorScheme="danger"
                    onPress={async () => {
                      console.log("DELETED ALL");
                      setIsDeleting(true);
                      await DeleteServerGroup(memoName, 1);
                      await LoadServerGroups(1);
                      setIsDeleting(false);
                      setAlertVisible(false);
                    }}
                  >
                    OK
                  </Button>
                  <Button onPress={() => setAlertVisible(false)}>Cancel</Button>
                </HStack>
              </VVDAlert>
            );
            setAlertVisible(true);
          }}
        />
        <HStack flex={1}>
          <Text flex={1} textAlign={"center"} p={1} bold color={"white"}>
            {memoName}
          </Text>
        </HStack>
        <Button
          // variant="solid"

          leftIcon={
            isDownloading ? null : memosObj.find((obj) =>
                obj.memoName.toLowerCase() === memoName.toLowerCase()
                  ? true
                  : false
              ) ? (
              <Icon as={<Ionicons name="md-checkmark-circle-outline" />} />
            ) : (
              <Icon as={<Ionicons name="code-download-outline" />} />
            )
          }
          
          _disabled={memosObj.find((obj) =>
            obj.memoName.toLowerCase() === memoName.toLowerCase() ? true : false
          )}
          variant={memosObj.find((obj) =>
            obj.memoName.toLowerCase() === memoName.toLowerCase()
              ? "subtle"
              : "solid"
          )}
          onPress={async () => {
            setIsDownloading(true);
            // console.log("click download", modalVisible);
            memosObj.find((obj) =>
              obj.memoName.toLowerCase() === memoName.toLowerCase()
                ? memoName + "_"
                : memoName
            );
            const tempMemoObj = {
              memoName: memoName,
              memoData: memoTxt,
            };

            let memosObjTemp = memosObj;

            memosObjTemp.push(tempMemoObj);

            setMemosObj(memosObjTemp);
            try {
              setMemosTotal(memosObjTemp.length);
            } catch (err) {
              console.error("setMemosTotal", err);
              setIsDownloading(false);
              return;
            }
            const jsonValue = JSON.stringify(memosObjTemp);
            await api
              .SetAsyncSetItem("@memodata", jsonValue)
              .then(() => {
                //setRefresh(false);
                console.log("SetAsyncSetItem", "GOOD");
                setIsDownloading(false);
              })
              .catch((err) => {
                console.error("SetAsyncSetItem err", err);
                setIsDownloading(false);
              });
          }}
        />
      </HStack>
      <Divider />
      <HStack width={"xs"}>
        <Text textAlign="left" ml={1} mr={1} color="white" flex={1}>
          {memoTxt}
        </Text>
      </HStack>
    </Stack>
  );
};

export default VVDMemoServerItem;
