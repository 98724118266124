import React from "react";
import { Button, Divider, Icon, IconButton, HStack, Stack, Text } from "native-base";
import { Ionicons, Entypo, MaterialCommunityIcons } from "@expo/vector-icons";

const VVDHelpBox = ({ guestMode, colorMode }) =>{
  return (
    <Stack bg={"gray.200"} mt="10" p="2" space={"2"} borderWidth="1">
      <HStack alignItems={"center"}>
        <IconButton
          width={"7"}
          height={"7"}
          icon={<Icon size="sm" as={<Entypo name="add-to-list" />} />}
          disabled
          _web={{ cursor: "arrow" }}
          mr="2"
        />
        <Text color={"black"}>Define a word.</Text>
      </HStack>
      <HStack alignItems={"center"}>
        <IconButton
          width={"7"}
          height={"7"}
          icon={<Icon size="sm" as={<Ionicons name="earth-outline" />} />}
          disabled
          _web={{ cursor: "arrow" }}
          mr="2"
        />
        <Text color={"black"}>Build description for sharing.</Text>
      </HStack>
      <HStack alignItems={"center"}>
        <Button mr="2" p="1" size={"xs"} disabled _web={{ cursor: "arrow" }}>
          Select an image.
        </Button>
        <Text color={"black"}>Image to analyze.</Text>
      </HStack>
      <HStack alignItems={"center"}>
        <IconButton
          width={"7"}
          height={"7"}
          icon={<Icon size="sm" as={<Ionicons name="md-pricetag" />} />}
          disabled
          _web={{ cursor: "arrow" }}
          mr="2"
        />
        <Text color={"black"}>Create tag local groups.</Text>
      </HStack>

      {!guestMode ? (
        <HStack alignItems={"center"}>
          <IconButton
            width={"7"}
            height={"7"}
            icon={
              <Icon
                size="sm"
                as={<Icon as={<Ionicons name="md-pricetags" />} />}
              />
            }
            disabled
            _web={{ cursor: "arrow" }}
            mr="2"
          />
          <Text color={"black"}>View saved tag groups on server.</Text>
        </HStack>
      ) : null}
      <HStack alignItems={"center"}>
        <IconButton
          width={"7"}
          height={"7"}
          icon={<Icon size="sm" as={<Ionicons name="moon-sharp" />} />}
          disabled
          _web={{ cursor: "arrow" }}
          // mr="2"
        />
        <Divider
          orientation="vertical"
          m={1}
          height={5}
          thickness={2}
          bg="gray.500"
        />
        <IconButton
          width={"7"}
          height={"7"}
          icon={<Icon size="sm" as={<Ionicons name="sunny-sharp" />} />}
          disabled
          _web={{ cursor: "arrow" }}
          mr="2"
        />
        <Text color={"black"}>Dark mode toggle.</Text>
        <Text pl="2" italic color={"black"}>
          Current: {colorMode ? "light" : "dark"}
        </Text>
      </HStack>
      {!guestMode ? (
        <HStack alignItems={"center"}>
          <IconButton
            width={"7"}
            height={"7"}
            icon={
              <Icon size="sm" as={<Ionicons name="cloud-upload-outline" />} />
            }
            disabled
            _web={{ cursor: "arrow" }}
            mr="2"
          />
          <Text color={"black"}>Upload tag group to server.</Text>
        </HStack>
      ) : null}
      <HStack alignItems={"center"}>
        <IconButton
          width={"7"}
          height={"7"}
          icon={<Icon size="sm" as={<Entypo name="help-with-circle" />} />}
          disabled
          _web={{ cursor: "arrow" }}
          mr="2"
        />
        <Text color={"black"}>Help.</Text>
      </HStack>
      <HStack alignItems={"center"}>
        <IconButton
          width={"7"}
          height={"7"}
          icon={<Icon size="sm" as={<Ionicons name="trash-outline" />} />}
          disabled
          _web={{ cursor: "arrow" }}
          mr="2"
        />
        <Text color={"black"}>Delete tag group.</Text>
      </HStack>
      <HStack alignItems={"center"}>
        <IconButton
          width={"7"}
          height={"7"}
          icon={
            <Icon
              size="sm"
              as={<MaterialCommunityIcons name="account-box-outline" />}
            />
          }
          disabled
          _web={{ cursor: "arrow" }}
          mr="2"
        />
        <Text color={"black"}>Account </Text>
        <Text italic color={"black"}>(sign-out, delete content, etc.)</Text>
      </HStack>
    </Stack>
  );
}

export default VVDHelpBox;
