import React from "react";
import { Popover} from "native-base";

const VVDpo = ({ definition, word }) => {
  return (
    <Popover.Content borderRadius={"xl"}>
      <Popover.Arrow />
      <Popover.CloseButton />
      <Popover.Header>{word}</Popover.Header>
      <Popover.Body>{definition}</Popover.Body>
    </Popover.Content>
  );
}

export default VVDpo;
