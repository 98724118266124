import React, { useState } from "react";
import {
  Box,
  Center,
  HStack,
  Icon,
  Button,
  Switch,
  Text,
  VStack,
  Pressable,
} from "native-base";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import VVDextraAdd from "./VVDextraAdd";

const VVDIGPrepItem = ({
  AddToAdd,
  GetStuff,
  GroupChosen,
  idx,
  loadTab,
  selected,
  setCurrentModal,
  setModalVisible,
  tabTagsSelCnt,
  totalTags,
  tabName,
  lmData,
  ftData,
}) => {
  const [isEnabled, setIsEnabled] = useState(selected);
  
  return (
    <HStack alignItems="center" width={"xs"} bgColor={"gray.700"} mt={1} p={1}>
      {isEnabled ? true : false}
      <Switch
        isChecked={isEnabled}
        key={idx}
        onToggle={(enabledState) => {
          setIsEnabled(enabledState);
          GroupChosen(enabledState, idx);
          GetStuff();
        }}
        trackColor={{ true: "#009900", false: "#ccc" }}
            onThumbColor={"#00cc00"}
      />
      <HStack flex={1}>
        <Center flex={1} alignContent={"center"}>
          <Text alignSelf="center" color="white" bold flexShrink={1}>
            {tabName}
          </Text>
        </Center>
        <Pressable
          onPress={() => {
            setCurrentModal(
              <VVDextraAdd
                key="VVDextraAdd"
                lmData={lmData}
                ftData={ftData}
                setModalVisible={setModalVisible}
                AddToAdd={AddToAdd}
              />
            );
            setModalVisible(true);
          }}
        >
          <VStack pr={1}>
            {lmData ? (
              <HStack alignItems={"center"}>
                <MaterialIcons name="location-on" color={"white"} />
                <Box bgColor={"green.500"} w={"10"} h={"2"} />
              </HStack>
            ) : null}
            <Text color="white" bold textAlign={"center"}>
              {tabTagsSelCnt} / {totalTags}
            </Text>
            {ftData ? (
              <HStack alignItems={"center"}>
                <MaterialIcons name="description" color={"white"} />
                <Box bgColor={"green.500"} w={"10"} h={"2"} />
              </HStack>
            ) : null}
          </VStack>
        </Pressable>
      </HStack>
      <Button
        variant="solid"
        leftIcon={
          <Icon
            as={<Ionicons name="arrow-forward-circle-outline" size={24} />}
          />
        }
        onPress={() => {
          loadTab(tabName, idx);
        }}
      />
    </HStack>
  );
};

export default VVDIGPrepItem;
