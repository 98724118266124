import React from "react";
import { AlertDialog } from "native-base";

const VVDAlert = ({ children, message, title}) => {
  return (
    <AlertDialog.Content {...{
      marginBottom: "auto",
      marginTop: 5,
    }}>
      <AlertDialog.CloseButton/>
      <AlertDialog.Header>{title}</AlertDialog.Header>
      <AlertDialog.Body>{message}</AlertDialog.Body>
      <AlertDialog.Footer>
        {children}
      </AlertDialog.Footer>
    </AlertDialog.Content>
  );
}

export default VVDAlert;
