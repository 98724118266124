import React, { useEffect, useState } from "react";
import {
  AlertDialog,
  Button,
  HStack,
  Icon,
  Modal,
  Text,
  Center,
  Spacer,
  Stack,
  Box,
} from "native-base";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";

import VVDAccountClearItem from "./VVDAccountClearItem";
import VVDAlert from "./VVDAlert";
import apiSrvr from "../utilsSrvr";
import api from "../utils";
import useAuth from "../auth/useAuth";
import authStorage from "../auth/storage";

import TOS from "./forms/TOS.js";

const VVDAccount = ({
  defaultMemoObj,
  defaultTabsObj,
  loadTab,
  setImageURI,
  setAllLbls,
  setModalVisible,
  setTabsObj,
  tabsObj,
}) => {
  const { user, setUser } = useAuth();
  const email = user;

  const [alertModal, setAlertModal] = useState(null);
  const [alertVisible, setAlertVisible] = useState(false);

  const [deleteMe, setDeleteMe] = useState([false, false, false, false]);

  useEffect(() => {
    //get tag details
  }, []);

  return (
    <Modal.Content
      {...{
        marginBottom: "auto",
        marginTop: 5,
      }}
    >
      <AlertDialog
        size="lg"
        isOpen={alertVisible}
        onClose={() => {
          setAlertVisible(false);
        }}
      >
        {alertModal}
      </AlertDialog>
      <Modal.CloseButton />
      <Modal.Header>Account Manager.</Modal.Header>

      <Modal.Body>
        {/* EVERYTHING CONTAINER  */}
        <Stack>
          {/* top row */}
          <Stack
            flexDir="row"
            // space="md"
            justifyContent="center"
            mb={1}
          >
            <Button
              mr={1}
              colorScheme="info"
              onPress={() => {
                setAlertModal(
                  <VVDAlert
                    title={TOS.TITLE}
                    message={TOS.MSG}
                    alertVisible={alertVisible}
                  >
                    <Button onPress={() => setAlertVisible(false)}>OK</Button>
                  </VVDAlert>
                );
                setAlertVisible(true);
              }}
            >
              Terms of Service
            </Button>

            <Button
              ml={1}
              rightIcon={<Icon as={Ionicons} name="exit-outline" size="sm" />}
              onPress={() => {
                setUser(null);
                authStorage.removeToken();
              }}
            >
              Sign Out
            </Button>
          </Stack>
          {/* <Divider mt={1} mb={2} /> */}

          {/* END TOP ROW */}
          <Spacer height="3" flex={1} />
          {/* REMAINDER OF DIALOG */}
          <Stack borderWidth="1" borderColor="black" bgColor={"orange.500"}>
            <Center>
              <Text bold flex="1">
                PERMANANT ACTIONS!
              </Text>
              <Text>(right is delete)</Text>
              <VVDAccountClearItem
                idx={0}
                deleteMe={deleteMe}
                setDeleteMe={setDeleteMe}
                tabName="ALL Server Memo(s)"
              />
              <VVDAccountClearItem
                idx={1}
                deleteMe={deleteMe}
                setDeleteMe={setDeleteMe}
                tabName="ALL Server Tag(s)"
              />
              <VVDAccountClearItem
                idx={2}
                deleteMe={deleteMe}
                setDeleteMe={setDeleteMe}
                tabName="ALL Local Memo(s)"
              />
              <VVDAccountClearItem
                idx={3}
                deleteMe={deleteMe}
                setDeleteMe={setDeleteMe}
                tabName="ALL Local Tag(s)"
              />
            </Center>
            <Button
              mt={2}
              alignSelf="center"
              rightIcon={<Icon as={Ionicons} name="trash-outline" size="sm" />}
              onPress={() => {
                setAlertModal(
                  <VVDAlert
                    title="CAUTION"
                    message={
                      <Box borderColor="#000" borderWidth="1" bg="#880808">
                        <Text bold italic textAlign="center">
                          {(deleteMe[0] ? "ALL Server Memo(s)\r \n" : "") +
                            (deleteMe[1] ? "ALL Server Tag(s)\r \n" : "") +
                            (deleteMe[2] ? "ALL Local Memo(s)\r \n" : "") +
                            (deleteMe[3] ? "ALL Local Tag(s)\r \n" : "") +
                            "WILL BE DELETED."}
                        </Text>
                      </Box>
                    }
                    setAlertVisible={setAlertVisible}
                  >
                    <HStack flexDirection="row" space="1">
                      <Button
                        colorScheme="danger"
                        onPress={async () => {
                          if (deleteMe[0]) {
                            console.log("DELETE ALL SERVER MEMO");
                            apiSrvr
                              .DeleteDataNode(1, email)
                              .then(() =>
                                console.log("DELETED ALL SERVER MEMO(S)")
                              )
                              .catch((err) =>
                                console.error(
                                  "ALL SERVER MEMO(S) NOT DELETED",
                                  err
                                )
                              );
                          }
                          if (deleteMe[1]) {
                            console.log("DELETE ALL SERVER TAGS");
                            apiSrvr
                              .DeleteDataNode(0, email)
                              .then(() =>
                                console.log("DELETED ALL SERVER TAG(S)")
                              )
                              .catch((err) =>
                                console.error(
                                  "ALL SERVER TAG(S) NOT DELETED",
                                  err
                                )
                              );
                          }
                          if (deleteMe[2]) {
                            console.log("DELETE ALL LOCAL MEMO");
                            const jsonValue = JSON.stringify(defaultMemoObj);
                            await api
                              .SetAsyncSetItem("@memodata", jsonValue)
                              .then(() =>
                                console.log("DELETED ALL LOCAL MEMO(S)")
                              )
                              .catch((err) =>
                                console.error(
                                  "ALL LOCAL MEMO(S) NOT DELETED",
                                  err
                                )
                              );
                          }
                          if (deleteMe[3]) {
                            console.log("DELETE ALL LOCAL TAGS", tabsObj);
                            try {
                              let tab = null;
                              tabsObj.forEach(async (obj) => {
                                tab = obj.tabName;
                                // console.log("DELETE tabsObj.forEach", obj);
                                await api
                                  .DeleteAsyncKey(tab)
                                  .then(() =>
                                    console.log("DELETED", obj.tabName)
                                  )
                                  .catch((err) =>
                                    console.error(
                                      obj.tabName + " NOT DELETED",
                                      err
                                    )
                                  );
                              });
                            } catch (error) {
                              console.error("tabsObj.forEach", error);
                            }
                            try {
                              defaultTabsObj[0].key = api.GenerateKey();
                            } catch (error) {
                              console.error("api.GenerateKey() error", error);
                            }

                            const jsonValue = JSON.stringify(defaultTabsObj);
                            try {
                              await api
                                .SetAsyncSetItem("@tabdata", jsonValue)
                                .then(() => {
                                  // console.log("DELETED SAVED", obj.tabName);
                                  console.log(
                                    "DELETED SAVED",
                                    defaultTabsObj[0].tabName
                                  );
                                })
                                .catch((err) =>
                                  console.error(
                                    obj.tabName + "DELETED NOT SAVED",
                                    err
                                  )
                                );
                            } catch (error) {
                              console.error(
                                "SetAsyncSetItem save delete",
                                error
                              );
                            }
                            setImageURI(null);
                            setAllLbls(null);
                            setTabsObj(defaultTabsObj);
                            loadTab("BLANK", 0);
                          }

                          setAlertVisible(false); /*DeleteAll()*/
                        }}
                      >
                        OK
                      </Button>
                      <Button onPress={() => setAlertVisible(false)}>
                        Cancel
                      </Button>
                    </HStack>
                  </VVDAlert>
                );
                setAlertVisible(true);
              }}
            >
              Delete Selected
            </Button>
            <Spacer height="5" flex={1} />
            <Button
              colorScheme="warning"
              borderWidth="3"
              borderColor="yellow.300"
              alignSelf="center"
              mb={3}
              mt={1}
              rightIcon={
                <Icon
                  as={MaterialCommunityIcons}
                  name="death-star-variant"
                  size="sm"
                />
              }
              onPress={() => {
                setAlertModal(
                  <VVDAlert
                    title="DOUBLE-PLUS BAD"
                    message="The account, local and server tag groups and memo data will be permanently detroyed."
                    setAlertVisible={setAlertVisible}
                    alertVisible={alertVisible}
                  >
                    <HStack flexDirection="row" space="1">
                      <Button
                        colorScheme="danger"
                        onPress={
                          async () => {
                            // <SignIn/>
                            //   try {
                            //     initializeApp(firebaseConfig);
                            //   } catch (err) {
                            //     console.log("ini", err);
                            //   }

                            //   const auth = getAuth();
                            // //connectAuthEmulator(auth, "http://localhost:9099");
                            //   console.log("DELETED ALL");
                            //   const temp = await authStorage.getToken();
                            //   console.log("getToken", temp);
                            //   //console.log("auth", auth.currentUser.uid);
                            //   const result = AuthFuncs.DU(auth, temp);
                            //   console.log(temp + "=deleted=", result);
                            await apiSrvr
                              .DeleteUser(email)
                              .then(() => console.log("DeleteUser SUCCESS"))
                              .catch((err) =>
                                console.error("DeleteUser ERR", err)
                              );
                            await api
                              .DeleteAsyncAllKeys()
                              .then(() =>
                                console.log("DeleteAsyncAllKeys SUCCESS")
                              )
                              .catch((err) =>
                                console.error("DeleteAsyncAllKeys ERR", err)
                              );
                            await authStorage
                              .removeToken()
                              .then(() => console.log("removeToken SUCCESS"))
                              .catch((err) =>
                                console.error("removeToken ERR", err)
                              );
                            setUser(null);
                            setAlertVisible(false);
                          } /*DeleteAll()*/
                        }
                      >
                        OK
                      </Button>
                      <Button onPress={() => setAlertVisible(false)}>
                        Cancel
                      </Button>
                    </HStack>
                  </VVDAlert>
                );
                setAlertVisible(true);
              }}
            >
              Delete Account
            </Button>
          </Stack>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group>
          <Button
            onPress={() => {
              setModalVisible(false);
            }}
          >
            Done
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  );
};

export default VVDAccount;
