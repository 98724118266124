import React, { useState } from "react";
import { Button, HStack, Icon, Stack, Text } from "native-base";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
const VVDListItemTXT = ({ colorMode, ico, togglerDisplay }) => {
  const [showTxt, setShowTxt] = useState(true);
  return (
    <>
      <Stack
        p={1}
        m={1}
        borderColor={colorMode ? "black" : "darkBlue.500"}
        borderWidth={1}
        width="xs"
        bgColor={colorMode ? "gray.100" : "darkBlue.900"}
      >
        <HStack
          width={"full"}
          justifyContent="space-evenly"
          alignItems="center"
        >
          {showTxt ? (
          <Text flex={3} ml={1} mr={1}>
            {togglerDisplay}
          </Text>):(
          <Text flex={3} ml={1} mr={1} textAlign={"right"} bold>
            {"Tap to show text =>"}
          </Text>
          )}
          <Button
            bgColor={colorMode ? "gray.100" : "darkBlue.900"}
            borderColor={colorMode ? "black" : "darkBlue.500"}
            leftIcon={
              <Icon
                as={
                  (ico === "txt" ? (
                    showTxt ? (
                      <MaterialCommunityIcons name="file-hidden" />
                    ) : (
                      <MaterialIcons name="description" />
                    )
                  ) : null) ||
                  (ico === "loc" ? (
                    showTxt ? (
                      <MaterialIcons name="location-off" />
                    ) : (
                      <MaterialIcons name="location-on" />
                    )
                  ) : null)
                }
              />
            }
            onPress={() => setShowTxt(!showTxt)}
            // onPress={() => console.log("ccccc")}
          />
        </HStack>
      </Stack>
    </>
  );
};

export default VVDListItemTXT;
