import React, { useState } from "react";
import {
  AlertDialog,
  Badge,
  Button,
  Divider,
  HStack,
  Icon,
  Popover,
  SimpleGrid,
  Stack,
  Text,
} from "native-base";
import { Switch } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import VVDpo from "./VVDpo";
import VVDAlert from "./VVDAlert";
import VVDListItemTXT from "./VVDListItemTXT";
import api from "../utils";

const VVDServerItem = ({
  colorMode,
  DeleteServerGroup,
  extraData,
  groupData,
  groupName,
  groupPrv,
  lmData,
  ftData,
  LoadServerGroups,
  setTagGroup,
  storeData,
  tabsObj,
  UpdateServerGroupsPrv,
}) => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [currentAlert, setCurrentAlert] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(groupPrv);

  return (
    <Stack
      borderColor="black"
      borderWidth="1"
      mb="1"
      alignItems="center"
      bgColor={"gray.700"}
      width={"350px"}
    >
      <AlertDialog
        size="lg"
        isOpen={alertVisible}
        onClose={() => {
          setAlertVisible(false);
        }}
      >
        {currentAlert}
      </AlertDialog>
      <HStack m={1} space={1} alignItems={"center"}  width="full">
        <Button
          ml={1}
          mb={1}
          variant="solid"
          leftIcon={<Icon as={<Ionicons name="trash-outline" />} />}
          isLoading={isDeleting}
          onPress={() => {
            setCurrentAlert(
              <VVDAlert
                title="CAUTION"
                message={
                  "'" +
                  groupName +
                  "' will be erased from the server, permanently."
                }
                setAlertVisible={setAlertVisible}
              >
                <HStack flexDirection="row" space="1">
                  <Button
                    colorScheme="danger"
                    onPress={() => {
                      setIsDeleting(true);
                      DeleteServerGroup(groupName);
                      LoadServerGroups();
                      setIsDeleting(false);
                      setAlertVisible(false);
                    }}
                  >
                    OK
                  </Button>
                  <Button
                    onPress={() => {
                      setAlertVisible(false);
                    }}
                  >
                    CANCEL
                  </Button>
                </HStack>
              </VVDAlert>
            );
            setAlertVisible(true);
          }}
        />
        <HStack flex={1} alignItems={"center"}>
          <Switch
            alignSelf={"center"}
            value={isEnabled}
            trackColor={{
              false: "#0891b2",
              true: "#880808",
            }}
            ios_backgroundColor={"#0891b2"}
            onValueChange={() => {
              const currentState = !isEnabled ? true : false;
              setIsEnabled(currentState);
              UpdateServerGroupsPrv(groupName, currentState);
            }}
          />
          <HStack flex={1} justifyContent="center">
            <Text
              alignSelf="center"
              p={1}
              bold={isEnabled}
              italic={isEnabled}
              isTruncated={true}
              color={"white"}
            >
              {groupName}
            </Text>
            <Badge alignSelf="center">{groupData.length}</Badge>
          </HStack>
        </HStack>
        <Button
          variant="solid"
          mr={1}
          mb={1}
          leftIcon={
            isDownloading ? (
              <Icon as={<Ionicons name="code-download-outline" />} />
            ) : tabsObj.find((obj) =>
                obj.tabName.toLowerCase() === groupName.toLowerCase()
                  ? true
                  : false
              ) ? (
              <Icon as={<Ionicons name="md-checkmark-circle-outline" />} />
            ) : (
              <Icon as={<Ionicons name="code-download-outline" />} />
            )
          }
          onPress={async () => {
            setIsDownloading(true);
            const tempName = "_" + groupName;
            const storeIt = api.TagGroupNameConflictCheck(tabsObj, groupName);

            const jsonGroupData = groupData;

            if (storeIt) {
              setCurrentAlert(
                <VVDAlert
                  title="CAUTION"
                  message={
                    "Tag group name '" +
                    groupName +
                    "' already exists.  Tag group name was prefixed with an underscore '" +
                    tempName +
                    "'."
                  }
                  setAlertVisible={setAlertVisible}
                >
                  <HStack flexDirection="row" space="1">
                    <Button
                      colorScheme="danger"
                      onPress={async () => {
                        await storeData(
                          storeIt ? tempName : groupName,
                          jsonGroupData,
                          false,
                          lmData,
                          ftData
                        );
                        await setTagGroup(
                          storeIt ? tempName : groupName,
                          jsonGroupData,
                          false
                        );
                        setIsDownloading(false);
                      }}
                    >
                      OK
                    </Button>
                    <Button
                      onPress={() => {
                        setAlertVisible(false);
                      }}
                    >
                      CANCEL
                    </Button>
                  </HStack>
                </VVDAlert>
              );
              setAlertVisible(true);
            } else {
              await storeData(groupName, jsonGroupData, false);
              await setTagGroup(groupName, jsonGroupData, false);
              setIsDownloading(false);
            }
          }}
        />
      </HStack>
      <Divider />
      {/* justifyContent="space-evenly"
          alignItems="center" */}
      <HStack
        mt="1"
        mb="1"
        // flexWrap={1}
        alignItems="center"
        justifyContent="space-evenly"
        flexDirection={"column"}
      >
        {lmData ? (
          <VVDListItemTXT
            colorMode={colorMode}
            togglerDisplay={lmData}
            ico={true}
          />
        ) : null}
        {ftData ? (
          <VVDListItemTXT
            colorMode={colorMode}
            togglerDisplay={ftData}
            ico={false}
          />
        ) : null}

        <SimpleGrid
          columns={1.45}
          spacingX={1}
          spacingY={1}
          marginVertical={2}
          alignItems="center"
        >
          {groupData.map((obj, index) => (
            <Popover
              key={index}
              trigger={(triggerProps) => {
                return (
                  <Button
                    flexWrap="wrap"
                    flex={1}
                    variant="solid"
                    disabled={!obj.definition}
                    endIcon={
                      obj.definition ? (
                        <Icon
                          size={"lg"}
                          as={
                            <Ionicons name="ellipsis-horizontal-circle-outline" />
                          }
                        />
                      ) : null
                    }
                    {...triggerProps}
                  >
                    {obj.word}
                  </Button>
                );
              }}
            >
              <VVDpo word={obj.word} definition={obj.definition} />
            </Popover>
          ))}
        </SimpleGrid>
      </HStack>
    </Stack>
  );
};

export default VVDServerItem;
