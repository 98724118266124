import React, { useState } from "react";
import {
  Actionsheet,
  Badge,
  Button,
  HStack,
  Icon,
  Input,
  Stack,
  Spacer,
  Switch,
  useDisclose,
} from "native-base";

import { Ionicons } from "@expo/vector-icons";

import VVDas from "../parts/VVDas";

const VVDListItem = ({
  allLbls,
  colorMode,
  CounterUpdate,
  definition,
  idxKey,
  onGetLabelDefClickHandler,
  others,
  selected,
  storeData,
  tabCurrent,
  word
}) => {
  const [isEnabled, setIsEnabled] = useState(selected);
  const [newText, setNewText] = useState(word);
  const [nowText, setNowText] = useState(word);
  const { isOpen, onOpen, onClose } = useDisclose();
  const [isLoading, setIsLoading] = useState(false);
  const asData1 = [];

  asData1.push({ others, definition, idxKey });

  return (
    <>
      <Stack
        p={1}
        m={1}
        borderColor={colorMode ? "black" : "red.500"}
        borderWidth={1}
        width="xs"
        bgColor={colorMode ? "gray.100" : "red.900"}
      >
        <HStack
          width={"full"}
          justifyContent="space-evenly"
          alignItems="center"
        >
          {(isEnabled === selected ? true : false)}
          <Switch
            isChecked={isEnabled}
            onToggle={(enabledState) => {
              setIsEnabled(enabledState);
              CounterUpdate(enabledState, idxKey);
              storeData(tabCurrent, allLbls);
            }}
            key={idxKey}
            trackColor={{ true: "#009900", false: "#ccc" }}
            onThumbColor={"#00cc00"}
          />

          <Input
            size="lg"
            flex={3}
            ml={1}
            mr={1}
            autoCapitalize="none"
            autoCompleteType="off"
            autoCorrect={false}
            defaultValue={word}
            onChangeText={(newText) => {
              setNewText(newText);
            }}
            onFocus={() => {
              setNowText(word);
            }}
            multiline={false}
          />
          {nowText !== newText ? (
            <Button
              isLoading={isLoading}
              mx="1"
              leftIcon={
                <Icon
                  size="sm"
                  as={<Ionicons name="refresh-circle-outline" />}
                />
              }
              onPress={async () => {
                setIsLoading(true);
                await onGetLabelDefClickHandler(newText, idxKey);
                setNowText(newText);
              }}
            />
          ) : null}

          {definition ? (
            <Button
              isDisabled={!definition}
              size="lg"
              startIcon={
                <Icon
                  size="md"
                  as={<Ionicons name="ellipsis-horizontal-circle-outline" />}
                />
              }
              onPress={onOpen}
            >
              <Badge>{others.length}</Badge>
            </Button>
          ) : (
            <Spacer height="10" alignSelf="flex-end" />
          )}
        </HStack>
      </Stack>
      {!asData1 ? null : (
        <Actionsheet isOpen={isOpen} onClose={onClose} size="lg">
          <VVDas
            data={asData1}
            onClose={onClose}
            onGetLabelDefClickHandler={onGetLabelDefClickHandler}
            word={word}
            idxKey={idxKey}
            colorMode={colorMode}
          />
        </Actionsheet>
      )}
    </>
  );
};

export default VVDListItem;
