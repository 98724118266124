const defaultAllLbls = [
  { key: null, definition: "", others: [], selected: false, word: "" },
];

const defaultTabsObj = [
  {
    key: null,
    tagCount: 0,
    totalTags: 0,
    tabName: "BLANK",
    selected: false,
  },
];
const defaultAllData = [
  {
    key: null,
    tagCount: 0,
    fullText: null,
    landMark: null,
    defaultAllLbls,
  },
];

const defaultMemoObj = [{ key: null, memoName: "", memoData: "" }];

export { defaultAllData, defaultAllLbls, defaultMemoObj, defaultTabsObj };
