import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "@firebase/auth";
// import { getAuth } from "@firebase/auth";
import { Badge, Box, FormControl, Text, Spacer, VStack } from "native-base";
import * as Yup from "yup";

import Padder from "../parts/Padder";
import {
  AppForm,
  FormError,
  FormField,
  FormSubmitButton,
  FormSwitch,
} from "../parts/forms";
import AuthFuncs from "../auth/AuthFuncs";
import useAuth from "../auth/useAuth";
import authStorage from "../auth/storage";
import firebaseConfig from "../../firebaseConfig";
import TOS from "../parts/forms/TOS";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
  password: Yup.string().required().min(4).label("Password"),
  confirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords don't match."
  ),
  agree: Yup.boolean().required().oneOf([true], "Must agree."),
  
});

const Create = () =>{
  const { user, setUser } = useAuth();
  const [formError, setFormError] = useState("");
  
  const handleSubmit = async (loginInfo) => {
    setFormError(
      await AuthFuncs.CA(auth, authStorage, setUser, loginInfo)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return err;
        }).finally(()=>{return;})
    );
  };

  try {
    initializeApp(firebaseConfig);
  } catch (err) {
    console.log("ini", err);
  }

  const auth = getAuth();
  //connectAuthEmulator(auth, "http://127.0.0.1:9099");

  useEffect(() => {
  
  }, []);

  return (
    <Badge flex={1} justifyContent="center">
      <Padder>
        <VStack>
          <AppForm
            initialValues={{
              email: "",
              password: "",
              confirm: "",
              agree: false,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <FormControl isRequired size="xs">
              <Box
                borderWidth="1"
                alignItems="center"
                padding="2"
                bg="gray.300"
              >
                <FormField
                  autoCapitalize="none"
                  autoCompleteType="off"
                  autoCorrect={false}
                  inputHelp="enter an email"
                  icon="email"
                  label="email"
                  name="email"
                  multiline={false}
                  type="email"
                  size="md"
                  width="100%"
                />
                <Spacer size="4" />
                <FormField
                  autoCapitalize="none"
                  autoCompleteType="off"
                  autoCorrect={false}
                  icon="lock"
                  label="password"
                  name="password"
                  multiline={false}
                  type="password"
                  size="md"
                  width="100%"
                />
                <Spacer size="2" />
                <FormField
                  autoCapitalize="none"
                  autoCompleteType="off"
                  autoCorrect={false}
                  //icon={pCText === pvCText ? "lock-check" : "lock-alert"}
                  label="confirm password"
                  name="confirm"
                  multiline={false}
                  type="password"
                  size="md"
                  width="100%"
                />
                <Spacer size="6" />
                <FormSwitch name="agree" />
                <Spacer size="6" />
                <FormSubmitButton title="Create" />
                <Spacer size="1" />
                <FormError error={formError} visible={formError} />
                <Text>{TOS.CPYRGT}</Text>
              </Box>
            </FormControl>
          </AppForm>
        </VStack>
      </Padder>
    </Badge>
  );
}
export default Create;