// import colors from "./colors";
import { getStatusBarHeight } from "react-native-iphone-x-helper";

export default {
  // actionButtonText: {
  //   color: colors.black,
  //   fontWeight: "bold",
  //   textAlign: "center",
  // },
  // actionButton: {
  //   justifyContent: "center",
  //   alignItems: "center",
  //   marginLeft: 10,
  //   marginRight: 10,
  // },
  // asDefinitionBox:{
  //   color: colors.black,
  //   borderWidth: 1,
  //   borderRadius: 30,
  //   width: "100%",
  // },
  // asDefinitionText: {
  //   color: colors.black,
  //   fontWeight: "bold",
  //   margin: 5,
  //   textAlign: "center",
  // },
  // asButton: { margin: 1 },
  // badgeText: { fontSize: 12, fontWeight: "bold", color: colors.black },
  // badge: { flexShrink: 1 },
  // badgeRow: { flexDirection: "row", alignItems: "center" },
  // buttonStyle: {
  //   borderRadius: 20,
  //   padding: 0,
  //   elevation: 2,
  //   marginHorizontal: 10,
  //   marginBottom: 5,
  //   width: 100,
  //   justifyContent: "center",
  // },
  // cicleBtn: {
  //   width: 36,
  //   height: 36,
  //   alignContent: "center",
  //   justifyContent: "center",
  // },
  // centeredView: {
  //   flex: 1,
  //   justifyContent: "center",
  //   alignItems: "center",
  //   marginTop: 22,
  // },
  deleteRow: {
    // backgroundColor: "red",
    height: 42,
    width: 42,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 10,
    top: 8,
  },
  // doneButton: {
  //   flexDirection: "row",
  //   alignContent: "center",
  //   justifyContent: "center",
  //   paddingTop: 5,
  //   paddingBottom: 3,
  // },
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    padding: 10,
    height: 100,
    backgroundColor: "transparent",
    paddingTop: getStatusBarHeight(),
  },
  // headerLeft: { flex: 0.1 },
  // headerMainBody: {
  //   flexGrow: 0.8,
  //   flexDirection: "row",
  //   justifyContent: "space-evenly",
  //   alignContent: "center",
  // },
  // headerRight: { flexGrow: 0.2 },
  // // height98: { height: "98%" },
  // inputTextStyle: {
  //   paddingBottom: 15,
  //   fontSize: 18,
  //   color: colors.black,
  //   textAlign: "left",
  // },
  // inputTextStyleCtr: {
  //   fontSize: 18,
  //   color: colors.black,
  //   textAlign: "center",
  //   // border: 1,
  // },
  // inputTextEdit: {
  //   flex: 1,
  //   height: 100,
  //   borderBottomWidth: 0,
  //   paddingLeft: 3,
  //   paddingRight: 3,
  // },
  // inputTextShow: {
  //   flex: 1,
  //   height: 100,
  //   borderTopWidth: 0,
  //   backgroundColor: colors.verylight,
  //   paddingLeft: 3,
  //   paddingRight: 3,
  // },
  // listInputText: { fontSize: 15, fontWeight: "bold", width: 100 },
  // listitemWH: {
  //   width: "98%",
  //   height: 70,
  // },
  // miniModalView: {
  //   backgroundColor: colors.light,
  //   borderRadius: 10,
  //   padding: 2,
  //   alignItems: "stretch",
  //   shadowColor: colors.modalShadowColor,
  //   shadowOffset: {
  //     width: 0,
  //     height: 2,
  //   },
  //   shadowOpacity: 0.25,
  //   shadowRadius: 4,
  //   elevation: 5,
  //   width: "98%",
  // },
  // miniCenteredView: {
  //   flex: 1,
  //   justifyContent: "center",
  //   alignItems: "center",
  // },
  // miniInput: {
  //   fontSize: 12,
  //   height: "100%",
  // },
  // miniRight: {
  //   flexDirection: "column",
  //   justifyContent: "center",
  //   alignContent: "center",
  //   height: "100%",
  // },
  // modalView: {
  //   margin: 10,
  //   backgroundColor: colors.lightmedium,
  //   borderRadius: 20,
  //   padding: 35,
  //   shadowColor: colors.modalShadowColor,
  //   shadowOffset: {
  //     width: 0,
  //     height: 2,
  //   },
  //   shadowOpacity: 0.25,
  //   shadowRadius: 4,
  //   elevation: 5,
  // },
  // othersText: { color: colors.black, fontWeight: "bold" },
  tab: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    // margin: 3,
    // padding: 7,
    // marginTop: 3,
    // paddingTop: 7,
    // paddingBottom: 3,
    // paddingLeft: 3,
    // paddingRight: 3,
    // height: 100,
    // width: 140,
  },
  // tabText: {
  //   color: colors.black,
  //   fontWeight: "bold",
  //   fontSize: 14,
  // },
  // wordText: { color: colors.black, fontWeight: "bold" },
};
