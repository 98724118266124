import React from "react";
import { AlertDialog } from "native-base";

import VVDHelpBox from "./VVDHelpBox";

const VVDHelp = ({ children, title, colorMode, guestMode }) =>{
  return (
    <AlertDialog.Content {...{
      marginBottom: "auto",
      marginTop: 5,
    }}>
      {/* <AlertDialog.CloseButton /> */}
      <AlertDialog.Header>{title}</AlertDialog.Header>
      <AlertDialog.Body>
        <VVDHelpBox colorMode={colorMode} guestMode={guestMode} />
      </AlertDialog.Body>
      <AlertDialog.Footer>{children}</AlertDialog.Footer>
    </AlertDialog.Content>
  );
}

export default VVDHelp;
