import React, { useState } from "react";
import { AlertDialog, Button, Input, Text, Modal } from "native-base";

import apiSrvr from "../utilsSrvr";
import useAuth from "../auth/useAuth";
import VVDAlert from "./VVDAlert";

const VVDMemoUpLoadDLG = ({
  memoLbl,
  memoTxt,
  setAlertInputVisible,
}) =>{
  const [newText, setNewText] = useState(memoLbl);
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);
  
  const [err, setShowErr] = useState(false);
  const { user, setUser } = useAuth();
  const email = user;

  const LoadServerGroupName = async () => {
    return await apiSrvr.GetStoredTagGroupNames(1, email);
  };

  return (
    <Modal.Content {...{
      marginBottom: "auto",
      marginTop: 5,
    }}>
      <AlertDialog
        size="lg"
        isOpen={alertVisible}
        onClose={() => {
          setAlertVisible(false);
        }}
      >
        {currentModal}
      </AlertDialog>
      <Modal.Header>Enter 'Memo' name to upload.</Modal.Header>
      <Modal.Body>
        <Input
          placeholder={memoLbl}
          size="lg"
          spellCheck={false}
          autoCapitalize={"none"}
          autoCompleteType={"off"}
          autoCorrect={false}
          onChangeText={(tempText) => {
            setNewText(tempText);
          }}
          autoFocus
        />
        {err ? (
          <Badge colorScheme="danger.600">
            <Text>Memo group name exists.</Text>
          </Badge>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button.Group>
          <Button
            isLoading={loading}
            onPress={async () => {
              setLoading(true);
              LoadServerGroupName().then((serverNameGroups) => {
                // console.log("LoadServerGroupName", LoadServerGroupName);
                const rslt = serverNameGroups.filter((wrd) => {
                  // console.log(newText + "=rslt", wrd);
                  return wrd.toLowerCase() === newText.toLowerCase();
                });
                // console.log("rslt rslt rslt", rslt.length);

                if (rslt.length !== 0) {
                  setCurrentModal(
                    <VVDAlert
                      title="CAUTION"
                      message={
                        "Memo name '" +
                        newText +
                        "' already exists.  Memo names must be unique."
                      }
                      setAlertVisible={setAlertVisible}
                    >
                      <Button onPress={() => setAlertVisible(false)}>OK</Button>
                    </VVDAlert>
                  );
                  setAlertVisible(true);
                  setLoading(false);
                } else {
                  const result = apiSrvr.uploadTagGroup(
                    newText,
                    JSON.stringify({ memoName: newText, memoData: memoTxt }),
                    1,
                    email
                  );
                  // console.log("conflict", result.then((a)=>a));
                  setLoading(false);
                  setAlertInputVisible(false);
                }
              });
            }}
          >
            OK
          </Button>
          <Button onPress={() => setAlertInputVisible(false)}>Cancel</Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  );
}

export default VVDMemoUpLoadDLG;
