import React from "react";
import {useFormikContext} from "formik";
import FormError from "./FormError";
import FormInput from "./FormInput";

const FormField = ({name, ...otherProps})=> {
    const {errors, handleChange, setFieldTouched, touched} = useFormikContext();
  return (
    <>
      <FormInput
        label={name}
        multiline={false}
        onBlur={() => setFieldTouched(name)}
        onChangeText={handleChange(name)}
        {...otherProps}
      />
      <FormError error={errors[name]} visible={touched[name]} />
    </>
  );
}

export default FormField;
