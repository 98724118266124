import {
  createUserWithEmailAndPassword,
  deleteUser,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInAnonymously,
} from "@firebase/auth";

import api from "../utils";

//create user
const CA = async (auth, authStorage, setUser, values) => {
  // console.log(email);
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      values.email,
      values.password
    );
    //console.error("userCredential", userCredential);
    setUser(userCredential.user.uid);
    authStorage.storeToken(userCredential.user.uid);
    api.SetAsyncSetItem("guestMode", "false");
    return "Account created!";
  } catch (error) {
    return error.message;
  }
};

//delete user
const DU = (auth, user) => {

  deleteUser(user)
    .then(() => {
      api.SetAsyncSetItem("guestMode", "false");
      // api.SetAsyncSetItem("guestMode", false);
      return true;
    })
    .catch((error) => {
      // An error ocurred
      return false;
    });
};

//reset password
const RE = (auth, values) => {
  //console.log("RESET", values.email);
  api.SetAsyncSetItem("guestMode", "false");
  return sendPasswordResetEmail(auth, values.email);
};

//signin
const SI = async (auth, authStorage, setUser, values) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      values.email,
      values.password
    );
    // console.error("userCredential", userCredential);
    setUser(userCredential.user.uid);
    authStorage.storeToken(userCredential.user.uid);
    api.SetAsyncSetItem("guestMode", "false");
    return "Success!";
  } catch (error) {
    return error.message;
  }
};

//anonymous
const AU = async (auth, authStorage, setUser, setGuestMode) => {
  try {
    const userCredential = await signInAnonymously(auth);

    setUser(userCredential.user.uid);
    authStorage.storeToken(userCredential.user.uid);
    // console.log("Success!");
    setGuestMode(true);
    api.SetAsyncSetItem("guestMode", "true");
    return "Success!";
  } catch (error) {
    //console.log("ANON FAIL", error.message);
    setGuestMode(false);
    api.SetAsyncSetItem("guestMode", "false");
    return error.message;
  }
};

export default { AU, CA, DU, RE, SI };
