import React, { useState } from "react";

import { AlertDialog, Button, Stack, Text } from "native-base";

import routes from "../auth/routes";
import TOS from "../parts/forms/TOS.js";
import VVDAlert from "../parts/VVDAlert";

const Welcome = ({ navigation }) => {
  const [alertModal, setAlertModal] = useState(null);
  const [alertVisible, setAlertVisible] = useState(false);
  return (
    <Stack flex={1} alignItems="center">
      <AlertDialog
        size="lg"
        isOpen={alertVisible}
        onClose={() => {
          setAlertVisible(false);
        }}
      >
        {alertModal}
      </AlertDialog>
      <Stack width={"2xs"} space={4}>
        <Text>
          theIMG.info is a utility eximietate, inc. created to discover words
          describing objects in an image, as well as create and organize
          captions and tag groups for re-use.
        </Text>

        <Button onPress={() => navigation.navigate(routes.SIGNIN)}>
          Sign-In
        </Button>

        <Button onPress={() => navigation.navigate(routes.CREATE)}>
          Create
        </Button>
        <Button onPress={() => navigation.navigate(routes.GUEST)}>Guest</Button>
        <Button
          mr={1}
          onPress={() => {
            setAlertModal(
              <VVDAlert
                title={TOS.TITLE}
                message={TOS.MSG}
                alertVisible={alertVisible}
              >
                <Button onPress={() => setAlertVisible(false)}>OK</Button>
              </VVDAlert>
            );
            setAlertVisible(true);
          }}
        >
          Terms of Service
        </Button>
      </Stack>
      <Text>{TOS.CPYRGT}</Text>
    </Stack>
  );
};

export default Welcome;
