import React, { useEffect, useLayoutEffect, useState } from "react";
import { FlatList, ScrollView } from "react-native";
import {
  Button,
  Center,
  HStack,
  Icon,
  Image,
  Modal,
  Spacer,
  Stack,
  Text,
} from "native-base";
import * as ImagePicker from "expo-image-picker";
import { SwipeListView } from "react-native-swipe-list-view";
import { Ionicons, Entypo, MaterialCommunityIcons } from "@expo/vector-icons";
import { getStatusBarHeight } from "react-native-iphone-x-helper";

import { initializeApp } from "@firebase/app";
import { getAnalytics, logEvent } from "@firebase/analytics";
import {
  getStorage,
  ref,
  uploadBytes,
  connectStorageEmulator,
  getDownloadURL,
  deleteObject,
} from "@firebase/storage";

import firebaseConfig from "../../firebaseConfig.js";

import {
  defaultAllData,
  defaultAllLbls,
  defaultTabsObj,
  defaultMemoObj,
} from "../config/defaultDataObj";

import VVDListItem from "../parts/VVDListItem";
import VVDTagGroupSaveDLG from "../parts/VVDTagGroupSaveDLG";
import VVDTagGroupUpLoadDLG from "../parts/VVDTagGroupUpLoadDLG";
import VVDCustomWordDLG from "../parts/VVDCustomWordDLG";
import VVDIGPrep from "../parts/VVDIGPrep";

import VVDServerList from "../parts/VVDServerList";
import VVDTabItem from "../parts/VVDTabItem";
import ListItemDeleteAction from "../parts/ListItemDeleteAction";

import api from "../utils";
import apiSrvr from "../utilsSrvr";

import Resizer from "../ImageSizer";
import VVDAccount from "../parts/VVDAccount.js";
import VVDAlert from "../parts/VVDAlert";
import TOS from "../parts/forms/TOS";
import VVDHelp from "../parts/VVDHelp.js";
import VVDHelpBox from "../parts/VVDHelpBox.js";
import VVDListItemTXT from "../parts/VVDListItemTXT.js";

// import routes from "../auth/routes.js";

// const Main1 = ({ props}) => {
const Main1 = ({ props, colorMode, cMode, shareMode, sMode }) => {
  let basePath = "";
  let funcPath = "";

  if (process.env.NODE_ENV === "development") {
    // funcPath = "http://127.0.0.1:5001/theimg-e296e/us-central1/app/";
    funcPath = "https://us-central1-theimg-e296e.cloudfunctions.net/app/";
    // funcPath = "http://127.0.0.1:5001/theimg-e296e/us-central1/app/";
  } else {
    funcPath = "https://us-central1-theimg-e296e.cloudfunctions.net/app/";
    // funcPath = "http://127.0.0.1:5001/theimg-e296e/us-central1/app/";
  }

  const [theTop, setTheTop] = useState(0);

  const [refresh, setRefresh] = useState(false);
  const [tabRefresh, setTabRefresh] = useState(false);
  const [imageURI, setImageURI] = useState(null);
  const [showImage, setShowImage] = useState(false);
  let [tagCount, setTagCount] = useState(defaultAllData[0].tagCount);

  const [imgProcStat, setImgProcStat] = useState("Defining...");

  const [allLbls, setAllLbls] = useState(null);
  const [lmData, setLMData] = useState(null);
  const [ftData, setFTData] = useState(null);
  // const [lmDataShow, setLMDataShow] = useState(true);
  // const [ftDataShow, setFTDataShow] = useState(true);

  const [tabsObj, setTabsObj] = useState(defaultTabsObj);

  const [tabCurrent, setTabCurrent] = useState("BLANK");
  const [tabIndex, setTabIndex] = useState(0);

  const [modalVisible, setModalVisible] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);

  const [loading, setLoading] = useState(false);

  const [guestMode, setGuestMode] = useState(false);

  const [searchToggle, setSearchToggle] = useState(true);
  const [analytics, setAnalytics] = useState(null);

  useLayoutEffect(() => {
    api.GetAsyncItem("guestMode").then((mode) => {
      setGuestMode(Boolean.valueOf(mode));
    });
    api.GetAsyncItem("colorMode").then((cm) => {
      cMode((!cm).valueOf());
    });
    api.GetAsyncItem("shareMode").then((sm) => {
      // console.log("GetAsyncItem shareMode b4", sm);

      sMode(sm.valueOf());
      // sMode(!Boolean.value
      // console.log("GetAsyncItem shareMode after", shareMode);
    });
  });

  useEffect(() => {
    requestPermission();
    try {
      initializeApp(firebaseConfig);
      setAnalytics(getAnalytics());
    } catch (err) {
      console.error("initializeApp ini", err);
    }
    setTheTop(getStatusBarHeight());

    api
      .GetAsyncTabsData(0)
      .then((onfulfilled) => {
        if (onfulfilled) {
          let tempTabObj = [];

          if (onfulfilled.length === 0) {
            resetBlankTab();
            loadTab("BLANK", 0);
          } else {
            tempTabObj = onfulfilled;
          }
          setTabsObj(tempTabObj);
          loadTab("BLANK", 0);
        } else {
          // console.log("GetAsyncTabsData", "NO stuff=");
          setTabsObj(defaultTabsObj);
          loadTab("BLANK", 0);
        }
      })
      .catch((onRejected) => {
        console.error("GetAsyncTabsData err", onRejected);
      });
  }, []);

  const setTagGroup = async (groupname, groupData, rfsh = true) => {
    // console.error("setTagGroup groupData fullText", groupData.fullText);
    storeTabData(groupname, groupData, rfsh)
      .then(() => {
        if (rfsh) setTabCurrent(groupname);
        return;
      })
      .catch((onRejected) => {
        console.error("setTagGroup onRejected err", onRejected);
      });

    if (rfsh) setModalVisible(false);
  };

  const resetBlankTab = () => {
    defaultTabsObj[0].key = api.GenerateKey();
    setTagGroup("BLANK", defaultTabsObj);
    return;
  };

  const storeTabData = async (fname, groupData = [], rfsh = true) => {
    //console.log("storeTabData groupData", groupData);
    let tempTagCount = null;
    try {
      tempTagCount = groupData.filter((obj) => {
        return obj.selected === true;
      });
    } catch (error) {
      console.error("storeTabData groupData.filter((obj)", error);
    }

    // console.error("=tempTagCount=", tempTagCount.length);
    const tempTabObj = {
      key: groupData.key === undefined ? api.GenerateKey() : groupData.key,
      tagCount: tempTagCount.length,
      totalTags: groupData.length,
      tabName: fname,
      selected: false,
    };

    let tabsObjTemp = tabsObj;
    tabsObjTemp.push(tempTabObj);

    setTabsObj(tabsObjTemp);

    if (rfsh) setRefresh(true);

    const jsonValue = JSON.stringify(tabsObjTemp);
    try {
      api
        .SetAsyncSetItem("@tabdata", jsonValue)
        .then(() => {
          if (rfsh) setRefresh(false);
        })
        .catch((err) => {
          console.error("SetAsyncSetItem", err);
          if (rfsh) setRefresh(false);
        });
    } catch (error) {
      console.error("storeTabData SetAsyncSetItem tabdata", error);
    }
  };

  const tabDataUpdate = async (fname, tagCount, ttlTags, rfsh = true) => {
    if (rfsh) setRefresh(true);
    const tempTabsObj = tabsObj;
    //get index in tabsObj
    let tempIndex = 0;
    //console.log(tagCount + "=" + fname + "=tabDataUpdate tabsObj", tabsObj);
    try {
      let counter = 0;
      tempTabsObj.forEach(() => {
        if (tempTabsObj[counter].tabName === fname) {
          tempIndex = counter;
        }
        counter++;
      });
    } catch (error) {
      console.error("tempTabsObj.findIndex", error);
    }
    //get object in tabsObj
    const tempObj = tempTabsObj[tempIndex];
    // console.log(tempIndex+"=tabDataUpdate tempIndex", tempObj);

    try {
      // console.log(tempIndex + "=tempObj.tagCount", tempObj);
      // console.log("tagCount", tagCount);
      tempObj.tagCount = tagCount;
    } catch (error) {
      console.error("tempObj.tagCount", error);
    }

    // tabsObj[tempIndex].selected = !tabsObj[tempIndex].selected;

    //   console.log("tabDataUpdate UPDATE", ttlTags);
    tabsObj[tempIndex].totalTags = ttlTags;
    tabsObj[tempIndex].lmData = lmData;
    tabsObj[tempIndex].ftData = ftData;
    tempTabsObj.splice(tempIndex, 1, tabsObj[tempIndex]);

    setTabsObj(tempTabsObj);
    const jsonValue = JSON.stringify(tempTabsObj);
    await api
      .SetAsyncSetItem("@tabdata", jsonValue)
      .then(() => {
        // console.log("tabdata saved");
      })
      .catch((err) => console.error("SetAsyncSetItem tabdata err", err));

    if (rfsh) setRefresh(false);
    return true;
  };

  const storeData = async (
    fname,
    tempAllLbls,
    rfsh = true,
    lm = lmData,
    ft = ftData
  ) => {
    let tempTagCount = 0;
    try {
      tempTagCount = tempAllLbls.filter((obj) => {
        return obj.selected === true;
      });
    } catch (err) {
      console.error("storeData TRY tempTagCount", err);
    }

    const tempLblObj = [
      {
        tagCount: tempTagCount.length,
        key: api.GenerateKey(),
        landMark: lm,
        fullText: ft,
      },
      tempAllLbls,
    ];

    if (rfsh) setRefresh(true);

    const jsonValue = JSON.stringify(tempLblObj);
    await api
      .SetAsyncSetItem(fname, jsonValue)
      .then(() => {
        if (rfsh) {
          tabDataUpdate(fname, tempTagCount.length, tempAllLbls.length, rfsh)
            .then(() => {
              // console.log("tabDataUpdate good");
            })
            .catch((err) => console.error("tabDataUpdate bad", err));
        }
        return;
      })
      .catch(() => console.error("SetAsyncSetItem", "BAD"));

    if (rfsh) setRefresh(false);
  };

  const CounterUpdate = (swt, id) => {
    // console.info("CounterUpdate=" + swt, id);
    swt ? (tagCount += 1) : (tagCount -= 1);
    setTagCount(tagCount);
    allLbls[id].selected = swt;
    setAllLbls(allLbls);
  };

  const requestPermission = async () => {
    const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (!granted) alert("Please allow permission to access photos.");
  };

  const selectImage = async () => {
    const tempAllLbls = allLbls;
    const tempTabCurrent = tabCurrent;
    setLoading(true);

    setAllLbls(tempAllLbls);
    setTabCurrent("BLANK");

    try {
      setImgProcStat("Waiting...");
      const result = await ImagePicker.launchImageLibraryAsync();

      if (!result.canceled) {
        setRefresh(true);
        setImgProcStat("Examining...");

        const portrait =
          result.assets[0].width <= result.assets[0].height ? true : false;
        let size = 1000;
        if (
          (portrait ? result.assets[0].height : result.assets[0].width) < 1000
        ) {
          size = portrait ? result.assets[0].height : result.assets[0].width;
        }

        const longSide = {
          resize: portrait ? { height: size } : { width: size },
        };

        const resizerResult = await Resizer(result.assets[0].uri, longSide);

        const fileName = Math.floor(
          Math.random() * (Math.random() * resizerResult.uri.length)
        );
        // console.log("fileName", fileName);
        //console.log(resizerTemp.length+"=resizerTemp", resizerTemp[resizerTemp.length]);
        setImageURI(resizerResult.uri);

        const tempImg = {
          uri: resizerResult.uri,
          type: "image/jpeg",
          name: fileName + ".jpg",
        };
        setRefresh(false);
        setLoading(false);
        try {
          storeImage(tempImg);
        } catch (error) {
          console.error("selectImage storeImage", error);
        }
      } else {
        setAllLbls(tempAllLbls);
        setTabCurrent(tempTabCurrent);
        setShowImage(true);
        setLoading(false);
      }
    } catch (error) {
      setRefresh(false);
      setLoading(false);
      console.error("Error reading an image.", error);
    }
  };

  const uploadtoFS = async (uri, name) => {
    //console.log("uri", name);

    try {
      initializeApp(firebaseConfig);
    } catch (err) {
      console.error("initializeApp ini", err);
    }

    const folder = getStorage();
    //connectStorageEmulator(folder, "127.0.0.1", 9199);
    const gsref = ref(folder, name);

    //storage
    const result = await fetch(uri);
    const blob = await result.blob();

    return uploadBytes(gsref, blob)
      .then(async (res) => {
        //const fPath = "gs://" + res.metadata.bucket + "/" + res.metadata.fullPath;
        const fPath = {
          bucketName: res.metadata.bucket,
          fileName: res.metadata.fullPath,
        };
        const obs = await getDownloadURL(gsref).then((dlu) => {
          const edlu = encodeURIComponent(dlu);

          return { fPath, gsref, edlu, searchToggle };
        });
        //console.log("-=-=-=-=-fPath", gsref);

        return obs;
      })
      .catch((ulerr) => {
        console.error("uploadBytes", ulerr);
        return;
      });
  };

  const storeImage = async (image) => {
    setRefresh(true);
    setLoading(true);
    // console.log("=storeImage", "enter=" + image.name);
    setImgProcStat("Processing...");
    const result = await uploadtoFS(image.uri, image.name);

    setTabCurrent("BLANK");

    setImgProcStat("Analyzing...");

    await apiSrvr
      .LabelMe(result)
      .then((data) => {
        // console.log("data[1].landMark", data[1].landMark);
        // console.log("data[2].fullText", data[2].fullText);

        setAllLbls(data[0].labelDefs);
        setLMData(data[1].landMark);
        setFTData(data[2].fullText);

        try {
          storeData(
            "BLANK",
            data[0].labelDefs,
            true,
            data[1].landMark,
            data[2].fullText
          )
            .then(() => console.log("storeData stored"))
            .catch((err) => console.error("storeData ERROR", err));
          // storeData("BLANK", allLbls, lmData, ftData);
        } catch (error) {
          console.error("apiSrvr.labelme storeData error", error);
        }
        // console.log(lmData, ftData);
      })
      .catch((err) => {
        console.error("apiSrvr.LabelMe catch err", err);
      });

    deleteObject(result.gsref)
      .then(() => {
        // console.log("IMAGE deleted");
        setImgProcStat("Deleted...");
      })
      .catch((err) => console.error("NOT DELETED", err));

    setTagCount(0);
    setLoading(false);
    setRefresh(false);
    setImgProcStat("DONE!");

    // console.log("storeImage", "leaving=");
  };

  const AddToAllLbls = async (obj, idx) => {
    // console.log(JSON.stringify(allLbls) + "=AddToAllLbls", obj);
    let a = [{}];
    if (allLbls === null) {
      a = [obj];
    } else {
      a = allLbls;
      a.push(obj);
    }

    setAllLbls(a);
    storeData(tabCurrent, a);

    a = [{}];
  };

  const onGetLabelDefClickHandler = async (theText, idx, update = false) => {
    setRefresh(true);
    let a = allLbls;
    let b = null;

    !update ? (b = await apiSrvr.onGetWord(theText)) : (b = update);

    try {
      a.splice(idx, 1, b[0]);
      setAllLbls(a);
      if (tabCurrent) {
        storeData(tabCurrent, a); //onGetLabelDefClickHandler
      }

      setRefresh(false);
    } catch (error) {
      setRefresh(false);
      console.error("ERROR setAllLbls", error);
    }
  };

  const loadTab = async (tabTitle, index) => {
    setTabRefresh(true);
    setModalVisible(false);
    setTabCurrent(tabTitle);
    setRefresh(true);

    //load data that was stored
    const temp1 = await api
      .GetAsyncItem(tabTitle)
      .then((rst) => rst)
      .catch((err) => console.error("GetAsyncItem=" + tabTitle, err));

    if (temp1) {
      setAllLbls(temp1[1]);
      setTagCount(temp1[0].tagCount);
      setLMData(temp1[0].landMark);
      setFTData(temp1[0].fullText);
    } else {
      setAllLbls(null);
      setTagCount(0);
      setLMData(null);
      setFTData(null);
    }
    setRefresh(false);
    setTabRefresh(false);
    setTabIndex(index);
  };

  const DeleteLocalGroup = async (tabCurr) => {
    //tabCurrent
    setRefresh(true);
    setTabRefresh(true);
    //console.log("tabsObj.length b4", tabsObj.length);
    await api.DeleteAsyncKey(tabCurr).then(() => {
      //console.log("api.DeleteAsyncKey", tabCurr);
      const tabsObjTemp = tabsObj.filter((item) => {
        return item.tabName !== tabCurr;
      });

      // console.log("=tabsObj.length after", tabsObjTemp.length);

      const jsonValue = JSON.stringify(tabsObjTemp);
      api
        .SetAsyncSetItem("@tabdata", jsonValue)
        .then(() => {
          //console.log("FILTER THEN");
          setLMData(null);
          setFTData(null);
          setTabsObj(tabsObjTemp);
          setTabRefresh(false);
          setRefresh(false);
          loadTab("BLANK", 0);
          // console.log("SetAsyncSetItem", "GOOD");
        })
        .catch((err) => {
          console.error("SetAsyncSetItem failed", err);
          setTabRefresh(false);
          setRefresh(false);
        });
    });
  };

  const renderHiddenItem = (data) => {
    return (
      <ListItemDeleteAction
        allLbls={allLbls}
        key={data.index}
        setAllLbls={setAllLbls}
        storeData={storeData}
        tabCurrent={tabCurrent}
        word={data.item.word}
      />
    );
  };

  return (
    <Stack flex={1} bgColor={colorMode ? "white" : "black"}>
      <Modal
        size="xl"
        isOpen={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
        avoidKeyboard
        safeAreaTop={true}
      >
        {currentModal}
      </Modal>

      <Center paddingTop={theTop}>
        <HStack width="xs">
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <Button
              // variant="solid"
              leftIcon={<Icon as={<Entypo name="add-to-list" />} />}
              onPress={async () => {
                setCurrentModal(
                  <VVDCustomWordDLG
                    key="VVDCustomWordDLG"
                    setRefresh={setRefresh}
                    AddToAllLbls={AddToAllLbls}
                    setModalVisible={setModalVisible}
                    logEvent={logEvent}
                    analytics={analytics}
                  />
                );
                setModalVisible(true);
              }}
              disabled={false}
            />
            <Spacer padding={1} />
            <Button
              leftIcon={<Icon as={<Ionicons name="earth-outline" />} />}
              onPress={async () => {
                setCurrentModal(
                  <VVDIGPrep
                    analytics={analytics}
                    colorMode={colorMode}
                    guestMode={guestMode}
                    defaultMemoObj={defaultMemoObj}
                    key="VVDIGPrep"
                    loadTab={loadTab}
                    logEvent={logEvent}
                    // tabDataUpdate={tabDataUpdate}
                    tabsObj={tabsObj}
                  />
                );
                setModalVisible(true);
              }}
            />
            <Spacer padding={1} />
            <Button
              onPress={selectImage}
              size="md"
              isLoading={loading}
              isLoadingText={imgProcStat}
            >
              Select an Image.{guestMode}
            </Button>
            <Spacer padding={1} />
            {imageURI ? (
              <Button
                // variant="solid"
                leftIcon={
                  <Icon
                    as={
                      showImage ? (
                        <Ionicons name="eye-outline" />
                      ) : (
                        <Ionicons name="eye-off-outline" />
                      )
                    }
                  />
                }
                onPress={() => {
                  setShowImage(!showImage);
                }}
              />
            ) : null}

            <Spacer padding={1} />

            <Button
              leftIcon={<Icon as={<Ionicons name="md-pricetag" />} />}
              // disabled={tabCurrent === "BLANK" && allLbls.length === 0}
              onPress={() => {
                setCurrentModal(
                  <VVDTagGroupSaveDLG
                    allLbls={allLbls}
                    key="VVDTagGroupSaveDLG"
                    loadTab={loadTab}
                    setTagGroup={setTagGroup}
                    setModalVisible={setModalVisible}
                    storeData={storeData}
                    tabsObj={tabsObj}
                  />
                );
                setModalVisible(true);
              }}
            />
            <Spacer padding={1} />
            <Button
              isDisabled={guestMode}
              disabled={guestMode}
              variant={guestMode ? "subtle" : "solid"}
              leftIcon={<Icon as={<Ionicons name="md-pricetags" />} />}
              onPress={() => {
                setCurrentModal(
                  <VVDServerList
                    colorMode={colorMode}
                    key="VVDServerList"
                    tabsObj={tabsObj}
                    setModalVisible={setModalVisible}
                    setTagGroup={setTagGroup}
                    storeData={storeData}
                    extraData={{ lmData, ftData }}
                  />
                );
                setModalVisible(true);
              }}
            />
            <Spacer padding={1} />
            <Button
              // variant="solid"
              leftIcon={
                <Icon
                  as={
                    colorMode === true ? (
                      <Ionicons name="moon-sharp" />
                    ) : (
                      <Ionicons name="sunny-sharp" />
                    )
                  }
                />
              }
              onPress={() => cMode(colorMode)}
            />
            <Spacer padding={1} />
            <Button
              isDisabled={guestMode}
              disabled={guestMode}
              variant={guestMode ? "subtle" : "solid"}
              leftIcon={<Icon as={<Ionicons name="cloud-upload-outline" />} />}
              onPress={() => {
                //console.log("ul tag group");
                setCurrentModal(
                  <VVDTagGroupUpLoadDLG
                    allLbls={allLbls}
                    key="VVDTagGroupUpLoadDLG"
                    setModalVisible={setModalVisible}
                    tabGroup={tabCurrent}
                    extraData={{ lmData, ftData }}
                  />
                );
                setModalVisible(true);
              }}
            />
            {/* <Spacer padding={1} />
            <Button
              variant={shareMode ? "subtle" : "solid"}
              leftIcon={<Icon as={<Ionicons name="share-social" />} />}
              onPress={() => {
                sMode(true);
              }}
            /> */}
            <Spacer padding={1} />
            <Button
              leftIcon={<Icon as={<Entypo name="help-with-circle" />} />}
              onPress={() => {
                setCurrentModal(
                  <VVDHelp
                    key="VVDHelp"
                    title="Help"
                    message={"Help"}
                    setModalVisible={setModalVisible}
                    colorMode={colorMode}
                    guestMode={guestMode}
                  >
                    <HStack flexDirection="row" space="1">
                      <Button onPress={() => setModalVisible(false)}>
                        Done
                      </Button>
                    </HStack>
                  </VVDHelp>
                );
                setModalVisible(true);
              }}
            />
            <Spacer padding={1} />
            <Button
              leftIcon={<Icon as={<Ionicons name="trash-outline" />} />}
              onPress={() => {
                setCurrentModal(
                  <VVDAlert
                    key="VVDAlert"
                    title="CAUTION"
                    message={
                      "Local tag group '" +
                      tabCurrent +
                      "' will be erased from this device."
                    }
                    setModalVisible={setModalVisible}
                  >
                    <HStack flexDirection="row" space="1">
                      <Button
                        colorScheme="danger"
                        onPress={async () => {
                          setRefresh(true);
                          //console.log("DELETE", tabCurrent);
                          if (tabCurrent === "BLANK") {
                            storeData("BLANK", [], true, null, null);
                            loadTab("BLANK", 0);
                            setFTData(null);
                            setLMData(null);
                          } else {
                            await DeleteLocalGroup(tabCurrent)
                              .then(() => {
                                console.log("Cleared", tabCurrent);
                                setFTData(null);
                                setLMData(null);
                              })
                              .catch((err) =>
                                console.error(
                                  "Unable to clear " + tabCurrent,
                                  err
                                )
                              );
                          }
                          setModalVisible(false);
                          setRefresh(false);
                        }}
                      >
                        OK
                      </Button>
                      <Button onPress={() => setModalVisible(false)}>
                        Cancel
                      </Button>
                    </HStack>
                  </VVDAlert>
                );
                setModalVisible(true);
              }}
            />
            <Spacer padding={1} />
            <Button
              variant="solid"
              leftIcon={
                <Icon
                  as={<MaterialCommunityIcons name="account-box-outline" />}
                />
              }
              onPress={() => {
                setCurrentModal(
                  <VVDAccount
                    defaultAllData={defaultAllData}
                    defaultMemoObj={defaultMemoObj}
                    defaultTabsObj={defaultTabsObj}
                    key="VVDAccount"
                    loadTab={loadTab}
                    tabDataUpdate={tabDataUpdate}
                    tabsObj={tabsObj}
                    setModalVisible={setModalVisible}
                    setAllLbls={setAllLbls}
                    setTabsObj={setTabsObj}
                    setImageURI={setImageURI}
                  />
                );
                setModalVisible(true);
              }}
            />
          </ScrollView>
        </HStack>
      </Center>
      <HStack width="xs" alignSelf={"center"}>
        {tabsObj.length > 0 && (
          <FlatList
            data={tabsObj}
            horizontal
            initialNumToRender={4}
            initialScrollIndex={tabIndex}
            keyExtractor={(item, index) => item.key}
            listKey="VVDTabItem"
            ListEmptyComponent={() => null}
            showsHorizontalScrollIndicator={false}
            scrollEnabled={true}
            onScrollToIndexFailed={(err, index) => {
              if (!err) {
                try {
                  scrollToIndex({
                    index: index,
                  });
                } catch (error) {
                  console.error("scrollToIndex", error);
                }
              }
            }}
            getItemLayout={(data, index) => ({
              length: 70,
              offset: 70 * index,
              index,
            })}
            renderItem={({ item, index }) => (
              <VVDTabItem
                keyID={index}
                listKey="VVDTabItemList"
                loadTab={loadTab}
                tabCurrent={tabCurrent}
                tabRefresh={tabRefresh}
                tabTagsTtlCnt={item.totalTags ? item.totalTags : 0}
                tabTagsSelCnt={item.tagCount}
                tabTitle={item.tabName}
                key={item.key}
              />
            )}
          />
        )}
      </HStack>
      <HStack justifyContent="center">
        {!showImage && imageURI ? (
          <>
            <Image source={{ uri: imageURI }} size="xl" alt="Selected image." />
          </>
        ) : null}
      </HStack>
      <Stack flex={1} alignItems="center">
        {lmData ? (
          // {lmData && lmDataShow ? (
          <VVDListItemTXT
            colorMode={colorMode}
            ico={"loc"}
            togglerDisplay={lmData}
          />
        ) : null}
        {/* {ftData && ftDataShow ? ( */}
        {ftData ? (
          <VVDListItemTXT
            colorMode={colorMode}
            ico={"txt"}
            togglerDisplay={ftData}
          />
        ) : null}

        <SwipeListView
          data={allLbls}
          listKey="VVDListItem"
          ListEmptyComponent={() => (
            <Center mt="1">
              <Image
                source={require("../../assets/sliding-buttons_v3.gif")}
                width="300"
                height="50"
                alt="Sliding buttons."
              />
              <VVDHelpBox colorMode={colorMode} guestMode={guestMode} />
            </Center>
          )}
          rightOpenValue={-50}
          renderHiddenItem={renderHiddenItem}
          keyExtractor={(item, index) => item.key}
          renderItem={({ item, index }) => (
            <VVDListItem
              allLbls={allLbls}
              colorMode={colorMode}
              CounterUpdate={CounterUpdate}
              definition={item.definition}
              idxKey={index}
              key={item.key}
              listKey="VVDListItemList"
              others={item.others}
              onGetLabelDefClickHandler={onGetLabelDefClickHandler}
              selected={item.selected}
              storeData={storeData}
              tabCurrent={tabCurrent}
              word={item.word}
            />
          )}
        />
      </Stack>

      <HStack justifyContent={"center"}>
        <Text>{TOS.CPYRGT}</Text>
      </HStack>
    </Stack>
  );
};

export default Main1;
