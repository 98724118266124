import React from "react";
import { SafeAreaView } from "react-native";

const Padder = ({ children }) => {
  return (
      <SafeAreaView>
      {children}
    </SafeAreaView>
  );
}

export default Padder;
