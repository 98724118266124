import React, { useState } from "react";
import { Button, Icon, Input, Modal } from "native-base";
import { Ionicons } from "@expo/vector-icons";

import api from "../utils";
import apiSrvr from "../utilsSrvr";

import VVDdefWrdTA from "./VVDdefWrdTA";

const VVDCustomWordDLG = ({
  AddToAllLbls,
  analytics,
  logEvent,
  setModalVisible,
  setRefresh,
}) => {
  const [newText, setNewText] = useState("");
  const [def, setDef] = useState("");
  const [btns, setBtns] = useState([]);
  const [tempObj, setTempObj] = useState(null);
  const [loading, setLoading] = useState(false);

  const ButtonMaker = async (wrds) => {
    let bunchObuttons = [];

    wrds.forEach((wrd) => {
      const tmpBtn = (
        <Button
          size="md"
          m={1}
          onPress={async () => {
            await WordButton(wrd);
          }}
          key={wrd}
        >
          {wrd}
        </Button>
      );
      bunchObuttons.push(tmpBtn);
    });
    setBtns(bunchObuttons);
  };

  const WordButton = async (wrd) => {
    // console.error("before", newText);
    setNewText(wrd);
    setLoading(true);
    setRefresh(true);
    const temp = await apiSrvr.onGetWord(wrd);
    // console.error("after", newText);
    if (temp !== undefined) {
      setTempObj(temp);
      setLoading(false); //0901
      if (temp[0].others) {
        await ButtonMaker(temp[0].others);
      }
      setDef(temp[0].definition);
      let tempItems = [];
      let inc = 0;
      temp[0].others.forEach((obj) => {
        tempItems.push({ item_name: obj, index: inc++ });
      });
      logEvent(analytics, "search", { search_term: wrd });
      logEvent(analytics, "select_item", {
        item_list_name: wrd,
        items: { tempItems },
      });
    }
    setRefresh(false);
    setLoading(false);
  };

  return (
    <Modal.Content
      {...{
        marginBottom: "auto",
        marginTop: 5,
      }}
    >
      <Modal.CloseButton
        onPress={() => {
          setLoading(false);
          setModalVisible(false);
        }}
      />
      <Modal.Header>Enter a word to define.</Modal.Header>

      <Modal.Body>
        {def ? <VVDdefWrdTA currentDef={def} setSelText={setNewText} /> : null}

        <Button.Group
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="center"
          padding={2}
        >
          {btns}
        </Button.Group>

        <Input
          InputLeftElement={
            <Icon as={<Ionicons name="ios-search" />} size="sm" />
          }
          size="lg"
          // defaultValue={newText}
          spellCheck={false}
          autoCapitalize={"none"}
          autoCompleteType={"off"}
          autoCorrect={false}
          placeholder="type word to define"
          onChangeText={(tempText) => {
            setNewText(tempText);
          }}
          autoFocus
          mb={4}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button.Group>
          <Button
            onPress={() => {
              setLoading(false);
              setRefresh(true);

              if (tempObj === null) {
                const tmpAll = {
                  key: null,
                  definition: "",
                  others: [],
                  selected: false,
                  word: "",
                };

                tmpAll.key = api.GenerateKey();
                tmpAll.word = newText;
                AddToAllLbls(tmpAll);
              } else {
                AddToAllLbls(tempObj[0]);
              }
              setTempObj(null);
              setRefresh(false);
              setModalVisible(false);
            }}
          >
            Add
          </Button>
          <Button
            onPress={() => {
              setLoading(false);
              setModalVisible(false);
            }}
          >
            Done
          </Button>
          <Button
            disabled={newText === ""}
            onPress={async () => {
              WordButton(newText);
            }}
            isLoading={loading}
            isLoadingText="Defining..."
          >
            Define
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  );
};
export default VVDCustomWordDLG;
