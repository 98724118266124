import React, { useState } from "react";
import { Button, Input, Modal } from "native-base";

import api from "../utils";

const VVDMemoSaveDLG = ({
  memosObj,
  memoTxt,
  setMemosTotal,
  setMemosObj,
  setAlertInputVisible,
}) => {
  const [newText, setNewText] = useState("");
  return (
    <Modal.Content {...{
      marginBottom: "auto",
      marginTop: 5,
    }}>
      <Modal.Header>Enter 'Memo' name to save.</Modal.Header>
      <Modal.Body>
        <Input
          size="lg"
          placeholder="type memo save name"
          spellCheck={false}
          autoCapitalize={"none"}
          autoCompleteType={"off"}
          autoCorrect={false}
          onChangeText={(tempText) => {
            setNewText(tempText);
          }}
          mb={3}
          autoFocus
        />

        <Modal.Footer>
          <Button.Group>
            <Button
              isDisabled={newText === ""}
              onPress={async () => {
                const tempMemoObj = {
                  memoName: newText,
                  memoData: memoTxt,
                };

                let memosObjTemp = memosObj;

                memosObjTemp.push(tempMemoObj);

                setMemosObj(memosObjTemp);
                try {
                  setMemosTotal(memosObjTemp.length);
                } catch (err) {
                  console.error("setMemosTotal", err);
                }
                const jsonValue = JSON.stringify(memosObjTemp);
                await api
                  .SetAsyncSetItem("@memodata", jsonValue)
                  .then(() => {
                    //setRefresh(false);
                    console.log("SetAsyncSetItem", "GOOD");
                    // return;
                  })
                  .catch((err) => {
                    console.error("SetAsyncSetItem err", err);
                  });
                setAlertInputVisible(false);
                //setAlertVisible(false);
                //setModalVisible(false);
              }}
            >
              OK
            </Button>
            <Button
              onPress={() => {
                setAlertInputVisible(false);
                //setAlertVisible(false);
                setModalVisible(false);
              }}
            >
              Cancel
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Body>
    </Modal.Content>
  );
};

export default VVDMemoSaveDLG;
