import React from "react";
import { Button, Modal, Pressable } from "native-base";

import VVDListItemTXT from "./VVDListItemTXT";

const VVDextraAdd = ({
  AddToAdd,
  colorMode,
  lmData,
  ftData,
  setModalVisible,
}) => {
  return (
    <Modal.Content
      {...{
        marginBottom: "auto",
        marginTop: 5,
      }}
    >
      <Modal.CloseButton />
      <Modal.Header>Additional Information</Modal.Header>
      <Modal.Body alignItems={"center"}>
        {lmData ? (
          <Pressable
            onPress={() => {
              AddToAdd(lmData);
              setModalVisible(false);
            }}
          >
            <VVDListItemTXT
              colorMode={colorMode}
              togglerDisplay={lmData}
              ico={"loc"}
            />
          </Pressable>
        ) : null}
        {ftData ? (
          <Pressable
            onPress={() => {
              AddToAdd(ftData);
              setModalVisible(false);
            }}
          >
            <VVDListItemTXT
              colorMode={colorMode}
              togglerDisplay={ftData}
              ico={"txt"}
            />
          </Pressable>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button.Group>
          <Button
            onPress={() => {
              setModalVisible(false);
            }}
          >
            Done
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  );
};

export default VVDextraAdd;
