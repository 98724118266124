import React, { useState } from "react";
import { Badge, Button, Input, Modal, Text } from "native-base";

import api from "../utils";
const VVDTagGroupSaveDLG = ({
  allLbls,
  loadTab,
  setModalVisible,
  setTagGroup,
  storeData,
  tabsObj,
}) => {
  const [newText, setNewText] = useState("");
  const [err, setShowErr] = useState(false);
  return (
    <Modal.Content {...{
      marginBottom: "auto",
      marginTop: 5,
    }}>
      <Modal.CloseButton />
      <Modal.Header>Enter 'Tag Group' name to save.</Modal.Header>
      <Modal.Body>
        <Input
          size="lg"
          spellCheck={false}
          placeholder="type tag group save name"
          autoCapitalize={"none"}
          autoCompleteType={"off"}
          autoCorrect={false}
          onChangeText={(tempText) => {
            setNewText(tempText);
          }}
          autoFocus
          mb={3}
        />
        {err ? (
          <Badge colorScheme="danger.600">
            <Text>Tag group name exists.</Text>
          </Badge>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button.Group>
          <Button
            isDisabled={newText === ""}
            onPress={async () => {
              const storeIt = api.TagGroupNameConflict(tabsObj, newText);

              try {
                if (storeIt === true) {
                  storeData(newText, allLbls, true); //modal
                  setTagGroup(newText, allLbls);
                  loadTab(newText);
                  
                } else {
                  setShowErr(true);
                }
              } catch (error) {
                console.error("TagGroupNameConflict", error);
              }
            }}
          >
            OK
          </Button>
          <Button
            onPress={() => {
              setShowErr(false);
              setModalVisible(false);
              setNewText("");
            }}
          >
            Cancel
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal.Content>
  );
};

export default VVDTagGroupSaveDLG;
