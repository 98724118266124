import React, { useEffect, useState } from "react";
import { Badge, Button, Divider, HStack } from "native-base";

const VVDTabItem=({
  keyID,
  loadTab,
  tabCurrent,
  tabTagsTtlCnt,
  tabTagsSelCnt,
  tabTitle,
}) =>{
  const [tabName, setTabName] = useState(null);
  useEffect(() => {
    setTabName(tabTitle !== "BLANK" ? tabTitle : "");
  }, []);

  return (
    <Button
      isDisabled={tabCurrent === tabTitle}
      value={keyID}
      m={1}
      p={1}
      onPress={() => {
        loadTab(tabTitle, keyID);
      }}
      key={keyID}
    >
      <HStack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        space={1}
      >
        <Badge rounded="md" bg={tabTagsSelCnt > 0 ? "#00cc00" : null}>
          {tabTagsSelCnt}
        </Badge>
        <Divider orientation="vertical" mx={1} height={5} thickness={2} />
        <Badge rounded="md">{tabTagsTtlCnt}</Badge>
      </HStack>
      <HStack justifyContent="center">
        {tabTitle !== "BLANK" ? (
          <Badge mt="1" variant="solid" rounded="md">
            {tabName}
          </Badge>
        ) : null}
      </HStack>
    </Button>
  );
}

export default VVDTabItem;
