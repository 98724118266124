import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Anon from "../screens/Anon.js";
import Create from "../screens/Create.js";
// import Shared from "../screens/Shared.js";
import SignIn from "../screens/SignIn.js";
import Welcome from "../screens/Welcome.js";

const Stack = createStackNavigator();

const AuthNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Welcome"
        options={{
          headerShown: true,
          headerTitle: "Signin, create an account, or continue as a Guest.",
        }}
        component={Welcome}
      />
      <Stack.Screen name="SignIn" component={SignIn} />
      <Stack.Screen name="Create" component={Create} />
      <Stack.Screen name="Guest" component={Anon} />
    </Stack.Navigator>
  );
};

export default AuthNavigator;