import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { HStack, Icon, Button } from "native-base";

import styles from "../config/styles";

const ListItemDeleteAction = ({
  allLbls,
  setAllLbls,
  storeData,
  tabCurrent,
  word,
}) => {
  const removeListItem = async (obj, word) => {
    return obj.filter((item) => {
      return item.word !== word;
    });
  };

  return (
    <HStack rounded="sm"
     style={styles.deleteRow}

    >
      <Button
        size="sm"
        
        
        leftIcon={<Icon bgColor="red" size="sm" as={<Ionicons name="trash-outline" />} />}
        onPress={async () => {
          const tempLbls = await removeListItem(allLbls, word);
          setAllLbls(tempLbls);
          await storeData(tabCurrent, tempLbls, true);
        }}
      />
    </HStack>
  );
};

export default ListItemDeleteAction;
