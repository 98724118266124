import React, { useState } from "react";
import { Text, Icon, Input } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const FormInput = ({
  icon,
  inputHelp = "type here",
  label = "default label value",
  temp, ...otherProps
}) => {
  const [inputText, setInputText] = useState(temp);
  return (
    <>
      <Text bold>{label}</Text>
      <Input
        InputLeftElement={
          icon && (
            <Icon
              as={<MaterialCommunityIcons name={icon} />}
              size="xs"
              ml="1"
            />
          )
        }
        defaultValue={inputText}
        onChangeText={(input) => {
          setInputText(input);
        }}
        placeholder={inputHelp}
        {...otherProps}
      />
    </>
  );
};

export default FormInput;
