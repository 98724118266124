import React from "react";
import { TextArea } from "native-base";

const VVDdefWrdTA = ({ currentDef, setSelText }) =>{
  return (
    <TextArea
      textAlign={"center"}
      selectable={true}
      editable={false}
      value={currentDef}
      size={"lg"}
      // w={"full"}
      scrollEnabled
      // borderWidth="1"
      // color={colorMode?"white":"black"}
      onSelectionChange={(sel) => {
        const tword = currentDef.substring(
          sel.nativeEvent.selection.start,
          sel.nativeEvent.selection.end
        );
        setSelText(tword);
      }}
    />
  );
}

export default VVDdefWrdTA;
