import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "@firebase/auth";
// import { getAuth } from "@firebase/auth";
import { Badge, Box, FormControl, Spacer, Text, VStack } from "native-base";
import * as Yup from "yup";

import Padder from "../parts/Padder";

import {
  AppForm,
  FormError,
  FormSubmitButton,
  FormSwitch,
} from "../parts/forms";

import AuthFuncs from "../auth/AuthFuncs.js";
import useAuth from "../auth/useAuth";
import authStorage from "../auth/storage";
import firebaseConfig from "../../firebaseConfig";

import TOS from "../parts/forms/TOS";

const validationSchema = Yup.object().shape({
  agree: Yup.boolean().required().oneOf([true], "Must agree."),
});

const Anon = ()=> {
  const [formError, setFormError] = useState("");

  const { user, setUser } = useAuth();
  const { guestMode, setGuestMode } = useAuth();

  const handleSubmit = async () => {
    
    setFormError(
      await AuthFuncs.AU(auth, authStorage, setUser, setGuestMode)
        .then((result) => {
          return result;
        })
        .catch((err) => {
           console.error("AuthFuncs.AU", err.message);
          return err;
        })
    );
  };

  try {
    initializeApp(firebaseConfig);
  } catch (err) {
    console.error("initializeApp", err);
  }

  const auth = getAuth();

  return (
    <Badge flex={1} justifyContent="center">
      <Padder>
        <VStack>
          <AppForm
            initialValues={{
              agree: false,
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <FormControl isRequired size="xs">
              <Box
                borderWidth="1"
                alignItems="center"
                padding="2"
                bg="gray.300"
              >
                <Spacer size="6" />
                <FormSwitch name="agree" />
                <Spacer size="6" />
                <FormSubmitButton title="Continue as Guest" />
                <Spacer size="1" />
                <FormError error={formError} visible={formError} />
                <Text>{TOS.CPYRGT}</Text>
              </Box>
            </FormControl>
          </AppForm>
        </VStack>
      </Padder>
    </Badge>
  );
}
export default Anon;