import React from "react";
import { Text } from "native-base";

const FormError = ({
  error,
  visible, ...otherProps
}) => {
  if (!visible||!error) return null;
  return <Text {...otherProps}>{error}</Text>;
};

export default FormError;
