import React, { useState, useEffect } from "react";
import { extendTheme, NativeBaseProvider } from "native-base";
import { NavigationContainer } from "@react-navigation/native";
import AuthContext from "./app/auth/context";
import AuthNavigator from "./app/auth/AuthNavigator";
import authStorage from "./app/auth/storage";
import Main from "./app/screens/Main";
import api from "./app/utils";

export default function App() {
  const [colorMode, toggleColorMode] = useState(true);
  const [shareMode, toggleShareMode] = useState(false);

  const cMode = (cm) => {
    api.SetAsyncSetItem("colorMode", (!cm).toString());
    toggleColorMode(!cm);
  };

  const sMode = (sm) => {
    api.SetAsyncSetItem("shareMode", sm.toString());
    toggleShareMode(sm);
  };

  const theme = extendTheme({
    config: {
      useSystemColorMode: false,
    },

    components: {
      ActionsheetContent: {
        defaultProps: {
          bg: colorMode ? null : "black",
        },
      },
      ActionsheetItem: {
        defaultProps: {
          bg: colorMode ? null : "black",
        },
      },
      AlertDialog: {
        defaultProps: {},
      },
      AlertDialogBody: {
        defaultProps: {
          bg: colorMode ? null : "gray.900",
          _text: {
            color: colorMode ? "black" : "white",
          },
        },
      },
      AlertDialogContent: {
        defaultProps: {
          borderWidth: "2",
          borderColor: colorMode ? "red" : "grey",
        },
      },
      AlertDialogFooter: {
        defaultProps: {
          bg: colorMode ? null : "black",
        },
      },
      AlertDialogHeader: {
        baseStyle: {
          _text: {
            fontWeight: "bold",
            fontStyle: "italic",
          },
        },
        defaultProps: {
          bg: colorMode ? null : "black",
          _text: {
            color: colorMode ? "black" : "white",
          },
        },
      },
      AlertDialogMessage: {
        defaultProps: {
          _text: colorMode ? "black" : "gray.900",
        },
      },
      Badge: {
        defaultProps: {
          bg: "gray.200",
          _text: {
            color: "black",
            fontWeight: "bold",
          },
        },
      },

      Button: {
        defaultProps: {
          borderWidth: "1",
          borderColor: colorMode ? "grey" : "black",
          bg: colorMode ? "black" : "red.700",
          _text: {
            color: "white",
            fontWeight: "bold",
          },
          _icon: {
            color: "white",
            size: "md",
          },
        },
      },
      Input: {
        defaultProps: {
          bgColor: colorMode ? "white" : "red.700",
          color: colorMode ? "black" : "white",
          borderColor: colorMode ? "black" : null,
        },
      },
      Icon: {
        defaultProps: {
          size: "md",
        },
      },
      IconButton: {
        defaultProps: {
          borderWidth: "1",
          borderColor: colorMode ? "grey" : "black",
          bg: colorMode ? "black" : "red.700",
          variant: "solid",
          _icon: {
            color: "white",
            size: "md",
          },
        },
      },
      Modal: {
        defaultProps: {},
      },
      ModalBody: {
        defaultProps: {
          bg: colorMode ? null : "gray.900",
        },
      },
      ModalContent: {
        defaultProps: {
          borderWidth: "2",
          borderColor: colorMode ? "black" : "grey",
        },
      },
      ModalFooter: {
        defaultProps: {
          bg: colorMode ? null : "black",
        },
      },
      ModalHeader: {
        baseStyle: {
          _text: {
            fontWeight: "bold",
            fontStyle: "italic",
          },
          defaultProps: {
            bg: colorMode ? null : "black",
          },
        },
        defaultProps: {
          bg: colorMode ? null : "black",
          _text: {
            color: colorMode ? "black" : "white",
          },
        },
      },
      TextArea: {
        defaultProps: {
          bg: colorMode ? "white" : "red.700",
          color: colorMode ? "black" : "white",
        },
      },
      Text: {
        defaultProps: {
          // bg: colorMode ? "white" : "red.700",
          color: colorMode ? "black" : "white",
        },
      },
    },
  });

  const [guestMode, setGuestMode] = useState(false);
  const [user, setUser] = useState();

  const retrieveUser = async () => {
    const user = await authStorage.getUser();

    //  user = await authStorage.removeToken();
    if (user) setUser(user);
  };

  useEffect(() => {
    retrieveUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, guestMode, setGuestMode }}>
      <NativeBaseProvider theme={theme}>
        <NavigationContainer
          documentTitle={{
            formatter: (options, route) => `${options?.title ?? "theIMG.info"}`,
          }}
        >
          {user ? (
            // shareMode ? (
            //   <Shared
            //     cMode={cMode}
            //     colorMode={colorMode}
            //     sMode={sMode}
            //     shareMode={shareMode}
            //   />
            // ) : (
              <Main
                cMode={cMode}
                colorMode={colorMode}
                sMode={sMode}
                shareMode={shareMode}
              />
            // )
          ) : (
            <AuthNavigator />
          )}
        </NavigationContainer>
      </NativeBaseProvider>
    </AuthContext.Provider>
  );
}
