import React from "react";
import { Button, Text, Modal } from "native-base";
import * as Yup from "yup";
import { AppForm, FormField, FormSubmitButton } from "./forms";

const VVDRSDLG = ({ auth, AuthFuncs, setModalVisible }) => {
  const handleSubmit = (values) => {
    AuthFuncs.RE(auth, values);
    setModalVisible(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
  });

  return (
    <Modal.Content {...{
      marginBottom: "auto",
      marginTop: 5,
    }}>
      <Modal.CloseButton />
      <Modal.Header>Enter 'Email' for password to reset.</Modal.Header>
      <Modal.Body>
        <AppForm
          initialValues={{ email: "" }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <FormField
            autoCapitalize="none"
            autoCompleteType="off"
            autoCorrect={false}
            inputHelp="enter an email"
            icon="email"
            name="email"
            multiline={false}
            type="email"
            size="md"
            width="full"
          />

          <Button.Group>
            <FormSubmitButton title="OK" />
            <Button
              onPress={() => {
                setModalVisible(false);
              }}
            >
              <Text>Cancel</Text>
            </Button>
          </Button.Group>
        </AppForm>
      </Modal.Body>
    </Modal.Content>
  );
};

export default VVDRSDLG;
