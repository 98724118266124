import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  AlertDialog,
  Badge,
  Button,
  HStack,
  Icon,
  Input,
  Modal,
  SimpleGrid,
  Stack,
  Text,
  TextArea,
  useClipboard,
  VStack,
} from "native-base";

import { Ionicons } from "@expo/vector-icons";
import VVDIGPrepItem from "./VVDIGPrepItem";

import VVDMemoSaveDLG from "../parts/VVDMemoSaveDLG";
import VVDMemoUpLoadDLG from "./VVDMemoUpLoadDLG";
import VVDMemoServerList from "./VVDMemoServerList";

import api from "../utils";

const VVDIGPrep = ({
  analytics,
  colorMode,
  defaultMemoObj,
  guestMode,
  loadTab,
  logEvent,
  tabsObj,
}) => {
  const [currentAlert, setCurrentAlert] = useState(null);
  const [currentModal, setCurrentModal] = useState(null);
  const [instaText, setInstaText] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [alertInputVisible, setAlertInputVisible] = useState(false);
  const [myText, setMyText] = useState("");
  const [myTextLbl, setMyTextLbl] = useState("");
  const [memosObj, setMemosObj] = useState(defaultMemoObj);
  const [memosCurNum, setMemosCurNum] = useState(0);
  const [memosTotal, setMemosTotal] = useState(0);
  const [tagCount, setTagCount] = useState(0);

  const { value, onCopy } = useClipboard();

  const UpDateMemoText = async (num) => {
    //console.log(memosObj.length + "=UpDateMemoText", num);
    setMemosCurNum(num);
    try {
      setMyText(memosObj[num].memoData);
      setMyTextLbl(memosObj[num].memoName);
      UpdateLocalMemo(num, memosObj[num].memoName, memosObj[num].memoData);
    } catch (e) {
      console.error("UpDateMemoText ERROR", e);
    }
  };

  const UpdateLocalMemo = async (tempIndex, lname, mData) => {
    //console.log("UpdateLocalMemo", lname);
    const tempMemoObj = memosObj;

    tempMemoObj.splice(tempIndex, 1, { memoName: lname, memoData: mData });
    setMemosObj(tempMemoObj);
    const jsonValue = JSON.stringify(tempMemoObj);
    await api.SetAsyncSetItem("@memodata", jsonValue);
  };

  const firstMemoUpdate = async (data) => {
    setMemosObj(data);
    setMyText(data[0].memoData);
    setMyTextLbl(data[0].memoName);
    setMemosTotal(data.length);
  };

  const GroupChosen = async (choice, tempIndex) => {
    tabsObj[tempIndex].selected = choice;

    // setTabsObj(tabsObj);
    const jsonValue = JSON.stringify(tabsObj);
    await api.SetAsyncSetItem("@tabdata", jsonValue);
  };

  const GetStuff = async () => {
    await api
      .GetTabLabels(tabsObj)
      .then((stuff) => {
        setInstaText(stuff[0]);
        setTagCount(stuff[1]);
      })
      .catch((err) => console.error("api.GetTabLabels", err));
  };

  const AddToAdd = (data) => {
    setMyText(myText + (myText === "" ? data : "\n" + data));
  };

  useLayoutEffect(() => {
    GetStuff();
  });

  useEffect(() => {
    const firstMemo = async (data) => {
      await firstMemoUpdate(data);
    };
    // GetStuff();
    //get memo details
    try {
      api
        .GetAsyncTabsData(1) //0=tab 1=memo
        .then((onfulfilled) => {
          if (onfulfilled != null) {
            setMemosObj(onfulfilled);
            firstMemo(onfulfilled);
          }
        })
        .catch((onRejected) => {
          console.error("GetAsyncTabsData onRejected", onRejected);
        });
    } catch (error) {
      console.log("GetAsyncTabsData ERROR", error);
    }
    // });
  }, []);

  return (
    <Modal.Content
      {...{
        marginBottom: "auto",
        marginTop: 5,
      }}
    >
      <Modal.CloseButton />
      <Modal.Header>Prep for Instagram.</Modal.Header>
      <Modal
        size="xl"
        closeOnOverlayClick={true}
        isOpen={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
      >
        {currentModal}
      </Modal>
      <AlertDialog
        size="lg"
        isOpen={alertInputVisible}
        onClose={() => {
          setAlertInputVisible(false);
        }}
      >
        {currentAlert}
      </AlertDialog>
      <Modal.Body>
        {/* EVERYTHING CONTAINER  */}
        <Stack space={1}>
          {/* top row */}
          <HStack flexDirection="row" justifyContent="space-evenly">
            <Button
              // colorScheme="light"
              p={1}
              variant="solid"
              onPress={() => {
                onCopy(myText + "\r\n" + instaText);
                logEvent(analytics, "DataCopied", {
                  StuffCopied: myText + "\r\n" + instaText,
                });
              }}
            >
              <HStack alignItems="center">
                <Text selectable={false} numberOfLines={1} bold color="white">
                  Copy Memo and Tag text.
                </Text>

                <Badge mx={2} bg={tagCount > 0 ? "#00cc00" : null}>
                  {tagCount}
                </Badge>
              </HStack>
            </Button>
          </HStack>
          {/* END TOP ROW */}

          {/* REMAINDER OF DIALOG */}
          <VStack flexDirection="column" space={1}>
            {/* MEMO SECTION */}
            <VStack flex={1} space={1}>
              <HStack flexDirection="row" justifyContent="center">
                <Button
                  // justifyContent="flex-start"
                  disabled={memosCurNum === 0}
                  isdisabled={memosCurNum === 0}
                  variant={memosCurNum === 0 ? "ghost" : "solid"}
                  leftIcon={<Icon as={<Ionicons name="trash" />} />}
                  onPress={async () => {
                    let memosObjTemp = await memosObj.filter((item) => {
                      return item.memoName !== memosObj[memosCurNum].memoName;
                    });
                    setMemosObj(memosObjTemp);
                    setMemosTotal(memosObjTemp.length);
                    setMyText(memosObjTemp[memosCurNum].memoData);
                    setMyTextLbl(memosObjTemp[memosCurNum].memoName);
                  }}
                />
                <Text
                  flex={1}
                  alignSelf="center"
                  textAlign="center"
                  color={colorMode ? "black" : "white"}
                >
                  {myTextLbl}
                </Text>
                <HStack justifyContent="flex-end" space={2}>
                  <Button
                    disabled={memosCurNum === 0}
                    // isdisabled={memosCurNum === 0}
                    variant={memosCurNum === 0 ? "ghost" : "solid"}
                    // variant="solid"
                    leftIcon={
                      <Icon as={<Ionicons name="arrow-back-circle" />} />
                    }
                    onPress={async () => {
                      // console.log("back = memosCurNum", memosCurNum);
                      await UpDateMemoText(memosCurNum - 1);
                    }}
                  />
                  {memosTotal > 0 ? (
                    <Text
                      alignSelf="center"
                      color={colorMode ? "black" : "white"}
                    >
                      {memosCurNum + 1}/{memosTotal}
                    </Text>
                  ) : null}

                  <Button
                    isdisabled={memosCurNum + 1 === memosTotal}
                    disabled={memosCurNum + 1 === memosTotal}
                    variant={
                      memosCurNum + 1 === memosTotal ? "subtle" : "solid"
                    }
                    leftIcon={
                      <Icon as={<Ionicons name="arrow-forward-circle" />} />
                    }
                    onPress={async () => {
                      await UpDateMemoText(memosCurNum + 1);
                    }}
                  />
                </HStack>
                <Button
                  ml="1"
                  leftIcon={<Icon as={<Ionicons name="bookmark" />} />}
                  onPress={() => {
                    setCurrentAlert(
                      <VVDMemoSaveDLG
                        key="VVDMemoSaveAlert"
                        alertInputVisible={alertInputVisible}
                        memosObj={memosObj}
                        memoTxt={myText}
                        setMemosTotal={setMemosTotal}
                        setMemosObj={setMemosObj}
                        setAlertInputVisible={setAlertInputVisible}
                      />
                    );
                    setAlertInputVisible(true);
                  }}
                />
              </HStack>

              <HStack flexDirection="row" flex={1} alignContent="center">
                <Input
                  size="md"
                  value={myText}
                  editable={!modalVisible}
                  multiline={true}
                  numberOfLines={4}
                  autoCapitalize="none"
                  autoCompleteType="off"
                  autoCorrect={false}
                  onChangeText={(newText) => {
                    setMyText(newText);
                  }}
                  placeholder="additional text"
                  scrollEnabled={true}
                  spellCheck={true}
                  flex={1}
                />
                <VStack ml="1" space={1} alignSelf="center">
                  <Button
                    isDisabled={guestMode} //guestMode
                    disabled={guestMode} //guestMode
                    variant={guestMode ? "subtle" : "solid"} //guestMode
                    alignSelf="center"
                    leftIcon={
                      <Icon as={<Ionicons name="cloud-upload-outline" />} />
                    }
                    onPress={async () => {
                      setCurrentAlert(
                        <VVDMemoUpLoadDLG
                          key="VVDMemoUpLoadAlrt"
                          memoTxt={myText}
                          memoLbl={myTextLbl}
                          setAlertInputVisible={setAlertInputVisible}
                        />
                      );
                      setAlertInputVisible(true);
                    }}
                  />
                  <Button
                    isDisabled={guestMode}
                    disabled={guestMode}
                    variant={guestMode ? "subtle" : "solid"}
                    leftIcon={
                      // isDownloading ? null : (
                      //   tabsObj.find((obj)=>obj.tabName.toLowerCase()===groupName.toLowerCase()?true:false)?<Icon size="sm" as={<Ionicons size="xl" name="md-checkmark-circle-outline" />}/>: <Icon size="sm" as={<Ionicons name="code-download-outline" />} />
                      // )

                      <Icon as={<Ionicons name="bookmarks" />} />
                    }
                    isLoading={isDownloading}
                    onPress={() => {
                      setCurrentModal(
                        <VVDMemoServerList
                          key="VVDMemoServerList"
                          memosObj={memosObj}
                          setMemosObj={setMemosObj}
                          setMemosTotal={setMemosTotal}
                          setModalVisible={setModalVisible}
                        />
                      );
                      setModalVisible(true);
                    }}
                  />
                </VStack>
              </HStack>
            </VStack>
            {/* MEMO SECTION */}

            {/* BEGIN TAGS SECTION */}
            <VStack>
              <TextArea
                size="md"
                caretHidden={true}
                value={instaText}
                editable={false}
                selectable={true}
                multiline={true}
                numberOfLines={4}
                scrollEnabled={true}
              />
            </VStack>
            <VStack>
              {tabsObj.length > 0 ? (
                <SimpleGrid
                  columns={1}
                  // bgColor={"red.300"}
                  alignSelf="center"
                >
                  {tabsObj.map((item, index) => (
                    <VVDIGPrepItem
                      AddToAdd={AddToAdd}
                      GetStuff={GetStuff}
                      GroupChosen={GroupChosen}
                      idx={index}
                      key={index}
                      loadTab={loadTab}
                      setModalVisible={setModalVisible}
                      setCurrentModal={setCurrentModal}
                      selected={item.selected}
                      tabName={item.tabName}
                      tabTagsSelCnt={item.tagCount}
                      totalTags={item.totalTags}
                      lmData={item.lmData}
                      ftData={item.ftData}
                    />
                  ))}
                </SimpleGrid>
              ) : null}
            </VStack>
            {/* END TAGS SECTION */}
          </VStack>
        </Stack>
      </Modal.Body>
      <Modal.Footer />
    </Modal.Content>
  );
};

export default VVDIGPrep;
