import React, { useState } from "react";
import {
  Actionsheet,
  Button,
  Divider,
  HStack,
  ScrollView,
  SimpleGrid,
  Text,
} from "native-base";
import apiSrvr from "../utilsSrvr";
import VVDdefWrdTA from "./VVDdefWrdTA";

const VVDas = ({ colorMode, data, idxKey, onClose, onGetLabelDefClickHandler, word }) => {
  const [tempData, setTempData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [currWord, setCurrWord] = useState(word);
  const [clickIndex, setClickIndex] = useState(null);
  const [selText, setSelText] = useState("");

  const DefineWord = async (newWord) => {
    setIsLoading(true);
    const a = await apiSrvr.onGetWord(newWord);
    if (a[0].definition !== "") {
      setTempData(a);
      setCurrWord(newWord);
    }

    setIsLoading(false);
    setSelText("");
  };

  return (
    <>
      <Actionsheet.Content width="full" alignSelf="center" role="">
        <HStack borderWidth="1" width={"100%"} flexDir="column">
          <HStack justifyContent={"center"} >
            <HStack flexWrap={"wrap"}>
              {word !== currWord && (
                <Text bold italic color={colorMode ? "black" : "white"}>
                  (original - {word}){" "}
                </Text>
              )}
              <Text bold color={colorMode ? "black" : "white"}>{currWord}</Text>
              <Text> :</Text>
            </HStack>
          </HStack>
          <VVDdefWrdTA
            currentDef={tempData[0].definition}
            setSelText={setSelText}
            flex={1}
            width="100%"
            colorMode={colorMode}
          />
        </HStack>
        {tempData[0].others && (
          <ScrollView width="full" showsVerticalScrollIndicator="true">
            <SimpleGrid
              columns={2.45}
              spacingX={1}
              spacingY={1}
              marginVertical={4}
              alignItems="center"
            >
              {tempData[0].others.map((obj, index) => (
                <Button
                  size="md"
                  key={index}
                  variant="solid"
                  onPress={async () => {
                    setClickIndex(index);
                    await DefineWord(obj);
                  }}
                  isLoading={isLoading && clickIndex === index}
                >
                  {obj}
                </Button>
              ))}
            </SimpleGrid>
          </ScrollView>
        )}
        <Divider orientation="horizontal" thickness={1} />
        
      <Actionsheet.Item alignItems="center">
        <HStack paddingBottom={2} space={1}>
          <Button
            size="md"
            variant="solid"
            onPress={() => {
              onGetLabelDefClickHandler(tempData[0].word, idxKey, tempData);
            }}
          >
            Update
          </Button>
          <Button size="md" variant="solid" onPress={onClose}>
            Done
          </Button>

          {selText ? (
            <Button
              size="md"
              variant="solid"
              onPress={async () => {
                await DefineWord(selText);
              }}
            >
              Define
            </Button>
          ) : null}
        </HStack>
      </Actionsheet.Item>
      </Actionsheet.Content>
    </>
  );
}

export default VVDas;
